import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { SignUp } from '../../Authentication/LoginForms/SignUp'
import { Login } from '../../Authentication/LoginForms/Login'
import { ForgotPassword } from '../../Authentication/LoginForms/ForgotPassword'
import { OneTimePassword } from '../../Authentication/LoginForms/OneTimePassword'

export const LoginRoutes = () => {
  return (
    <div>
        <Routes>
            <Route path='/SignUp' element={<SignUp />} />
            <Route path='/Login' element={<Login />} />
            <Route path='Login/ForgotPassword' element={<ForgotPassword />} />
            <Route path='SignUp/verification' element={<OneTimePassword />} />
        </Routes>
    </div>
  )
}
