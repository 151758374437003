import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { FaTruckField } from 'react-icons/fa6'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Select from 'react-select'
import { Input } from 'react-select/animated'
import { REAPI } from '../../serviceLayer/Interceptor'
import '../../assets/css/login.css'
import logo from '../../assets/images/Teks_Skill_HubLogo_05.png'
import { GoCheckCircleFill } from "react-icons/go";


export const SignUp = () => {

    
    // const passowrdRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    const navigation = useNavigate()

    // otp Disable
    const [OtpDisable, setOptDisable] = useState(false)
    // state for hiding the verification
    const [isInputs, setInputs] = useState({
        mailVerification: true,
        mobileVerification: true,
        submitBtn: true,
        generateOTPLoading:false,
    })

    // loading State
    const [loading, setLoading] = useState(false)
    // error state
    const [errors, setError] = useState({})

    // formData storing state
    const [formData, setFormData] = useState({
        FullName: "",
        email: "",
        phonenumber: "",
        password: "",
        roleid: "",
        isVerified: false,
    })

    // unable button after all fields filling using useEffect
    useEffect(() => {
        if (formData.FullName != "" && formData.email != "" && formData.phonenumber != "" && formData.password != "" && formData.roleid != "" && formData.isVerified != false) {
            setInputs({ ...isInputs, submitBtn: false })
        }
        else {
            setInputs({ ...isInputs, submitBtn: true })

        }
        // console.log(isInputs.submitBtn, "verifede")
    }, [formData.email, formData.FullName, formData.isVerified, formData.password, formData.phonenumber, formData.roleid, isInputs.submitBtn])

    // email OTP button state changing
    const [isValidMail, setValidMail] = useState(false)

    useEffect(() => { setValidMail(false) }, [formData.email])

    // email otp verification state
    const [mailOtp, setMailOtp] = useState()

    // contact OTP button state
    const [isValidNumber, setValidNumber] = useState(false)

    // moblie otp verification state
    const [mobileOtp, setMobileOtp] = useState()


    // input change handle
    const handleInput = (e) => {
        console.log(formData,"formdata")
        e.preventDefault();
        const { id, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [id]: value,
        }))
        setError({
            ...errors,
            [id]: ""
        })
        // console.log(formData, "data")
    }

    // select handling
    const handleRoles = (selectedOption) => {
        setFormData({
            ...formData,
            roleid: selectedOption.value,
        });
        setError({
            ...errors,
            roleid: ""
        })
    };

    // validations
    const validateForm = () => {
        let formErrors = {};

        if (!formData.FullName) {
            formErrors.FullName = "Name is required";
            return formErrors;
        } else if (formData.FullName.length < 3) {
            formErrors.FullName = "Name must contain at least 3 characters";
            return formErrors;
        }
        if (!formData.email) {
            formErrors.email = "Email is required";
            return formErrors;
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            formErrors.email = "Invalid email address";
            return formErrors;
        }
        if (!formData.phonenumber) {
            formErrors.phonenumber = "Phone number is required";
            return formErrors;
        } else if (formData.phonenumber.length !== 10) {
            formErrors.phonenumber = "Phone number must be 10 digits";
            return formErrors;
        }
        if (formData.password.length < 6) {
            formErrors.password = "password should contain atleast 6 character";
            return formErrors;
        }
        if (!formData.roleid) {
            formErrors.roleid = "please choose the signup role";
            return formErrors;
        }

        return formErrors;
    }

    // Login Options
    const roleOptions = [
        { value: 1, label: "Student" },
        { value: 2, label: "Trainer" },
    ]


    // Submiting 
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        // console.log("clicked submite")
        const formErrors = validateForm();
        if (Object.keys(formErrors) != 0) {
            setError(formErrors)
            // console.log(formErrors)
            return
        }

        try {
            const response = await REAPI.post(`/user/createuser`, formData)
            // console.log(response)
            navigation("/authentication/Login")
        } catch (error) {
            // console.log(error, "submission error")
            setLoading(false)
            setError({
                ...errors,
                SubmitError: error.response.data.error,
            })
        }
        finally {
            setLoading(false)

        }
        // console.log(formData, "DAta")
    }

    // email OTP button state changing

    // buttons loading state pending
    const handleSendMail = async (e) => {

        e.preventDefault();
        if (!/\S+@\S+\.\S+/.test(formData.email)) {
            setError({
                ...errors,
                email: "Invalid email address"
            })
            return;
        }
        const reqData = {
            email: formData.email
        }

        try {
            // console.log("mail sent")
            setOptDisable(true)
            const response = await REAPI.post(`/otp/send`, reqData)

            setValidMail(!isValidMail)
        } catch (error) {
            setError({
                ...errors,
                SubmitError: error.response.data.error,
            })
            setOptDisable(false)
        }
        finally{
            setOptDisable(false)
        }
        // console.log("clicked email")
    }

    // email otp verification function
    const validateMailOTP = async (e) => {

        e.preventDefault()
        const reqData = {
            email: formData.email,
            otpCode: parseInt(mailOtp),

        }

        try {
            setOptDisable(true)
            const response = await REAPI.post(`/otp/verify`, reqData)
            setInputs(prev => ({
                ...prev,
                mailVerification: false
            }))
            setFormData({
                ...formData,
                isVerified: true
            })
        } catch (error) {
            setError({
                ...errors,
                SubmitError: error.response.data.error,
            })
            // setInputs({...isInputs,generateOTPLoading:false})
            // console.log(error)
        }
        finally{
            // setInputs({...isInputs,generateOTPLoading:false})
            setOptDisable(false)
        }

        // console.log("verified")
    }


    // mobile otp verification is pending from the backen side
    // mobile otp verification is pending from the backen side
    // mobile otp verification is pending from the backen side
    // mobile otp verification is pending from the backen side
    // mobile otp verification is pending from the backen side
    // mobile otp verification is pending from the backen side



    // mobile otp generate Function

    // const handleMobileOtp = async(e)=>{
    //     e.preventDefault();

    //     const reqData ={
    //         phonenumber:parseInt(formData.phonenumber),

    //     }
    //     try {
    //         // const response = await axios.post("http://192.168.1.107:3030/otp/send", reqData)
    //         setValidNumber(!isValidNumber)
    //     } catch (error) {
    //         console.log(error,"mobile otp generation error")
    //     }
    // }

    // // mopile otp verification function
    // const valideMobileOTP= async (e)=>{
    //     console.log("mobile otp clicked")
    //     e.preventDefault();
    //     const reqData={
    //         phonenumber:formData.phonenumber,
    //         otpCode:parseInt(mobileOtp),
    //     }

    //     try {
    //         // const response = await axios.post("http://192.168.1.107:3030/otp/verify", reqData)
    //     } catch (error) {
    //         console.log("mobile otp verification error")
    //     }
    // }



    return (
        <div className="login_bg_image w-full">


            <div className='container '>
                <div className="row justify-content-center">
                    <div className="col-12 text-center mt-sm-3 mb-1 text-white-50">
                        <img src={logo} alt="" srcset="" style={{ width: "230px" }} />
                    </div>
                    <div className="col-md-8 col-lg-6 col-xl-5">
                        <div className="shadow p-4 rounded mt-4 bg-white">
                            <h2 className="text-center mb-3 teks_blue fs-16 fw-500">Signup to your Account!</h2>
                            <form>
                                <div className="mb-2">
                                    <label htmlFor="name" className="  fs-13 fw-500 mb-2">Full Name</label>
                                    <input type="text" className="form-control fs-s bg-form text_color input_bg_color" id="FullName" placeholder='Enter full name' onChange={(e) => handleInput(e)} value={formData.FullName} />
                                    {errors && errors.FullName && (
                                        <p className="text-danger m-0 fs-xs">
                                            {errors.FullName}
                                        </p>
                                    )}
                                </div>
                                <div className="mb-2">
                                    <label htmlFor="email" className="  fs-13 fw-500 mb-2">Email</label>
                                    <div className="position-relative">

                                    
                                    <input type="email" className="form-control fs-s bg-form text_color input_bg_color" id="email" placeholder='Enter email' onChange={(e) => handleInput(e)} value={formData.email} disabled={Input.mailVerification} />
                                    {isInputs.mailVerification && formData && formData.email && (
                                        <div className="mt-3 d-flex gap-1">
                                            {isValidMail && (<input type="number" className="form-control fs-s bg-form text_color input_bg_color flex-2" id="email" placeholder='Enter OTP' maxLength={6} onChange={(e) => setMailOtp(e.target.value)} onInput={!isValidMail ? undefined: (e)=>{if(e.target.value>6)e.target.value=e.target.value.slice(0,6)}} />)}
                                            <button className={` btn bg-info text-white ${!isValidMail ? "w-100" : "w-50"}`} onClick={!isValidMail ? (e) => handleSendMail(e) : (e) => validateMailOTP(e)}disabled={OtpDisable}>{!isValidMail ? <span>Generate OTP</span> : <span>Verify OTP</span>}</button>
                                        </div>
                                    )}
                                    {!isInputs.mailVerification && (<div className="position-absolute end-0 px-2 py-1 text-success top-0">
                                    <GoCheckCircleFill />
                                        </div>) }
                                    </div>
                                    {errors && errors.email && (
                                        <p className="text-danger m-0 fs-xs">
                                            {errors.email}
                                        </p>
                                    )}
                                </div>
                                <div className="mb-2">
                                    <label htmlFor="phonenumber" className="  fs-13 fw-500 mb-2">Contact</label>
                                    <input type="number" className="form-control fs-s bg-form text_color input_bg_color" id="phonenumber" placeholder='Enter phonenumber' onChange={(e) => handleInput(e)} maxLength='10' value={formData.phonenumber}
                                     onInput={(e)=>{
                                        if(e.target.value.length>10){
                                            e.target.value = e.target.value.slice(0,10)
                                        }
                                     }} />
                                    {/* {isInput.mobileVerification && formData && formData.phonenumber && (
                                    <div className="mt-3 d-flex gap-1">
                                        {isValidNumber && (<input type="number" className="form-control fs-s bg-form text_color input_bg_color flex-2" id="email" placeholder='Enter OTP' maxLength={6} onChange={(e) => setMobileOtp(e.target.value)} />)}
                                        <button className={` btn bg-info text-white ${!isValidNumber ? "w-100" : "w-50"}`} onClick={isValidNumber ? (e) => valideMobileOTP(e) : (e) => handleMobileOtp(e)}>{!isValidNumber ? <span>Generate OTP</span> : <span>Verify OTP</span>}</button>
                                    </div>
                                )} */}
                                    {errors && errors.phonenumber && (
                                        <p className="text-danger m-0 fs-xs">
                                            {errors.phonenumber}
                                        </p>
                                    )}
                                </div>
                                <div className="mb-2">
                                    <label htmlFor="password" className="  fs-13 fw-500 mb-2">Password</label>
                                    <input type="password" className="form-control fs-s bg-form text_color input_bg_color" id="password" placeholder='Password' onChange={(e) => handleInput(e)} maxLength='16' value={formData.password} />
                                    {errors && errors.password && (
                                        <p className="text-danger m-0 fs-xs">
                                            {errors.password}
                                        </p>
                                    )}
                                </div>
                                <div className="mb-2">
                                    <label htmlFor="roleid" className="  fs-13 fw-500 mb-2">Signup as</label>
                                    <Select
                                        id='roleid'
                                        onChange={handleRoles}
                                        className="basic-single fs-s"
                                        classNamePrefix="select"
                                        placeholder="Signup as.."
                                        options={roleOptions}
                                    />
                                    {errors && errors.roleid && (
                                        <p className="text-danger m-0 fs-xs">
                                            {errors.roleid}
                                        </p>
                                    )}
                                </div>
                                <div className="my-3">
                                    <p className=' fs-13 fw-500 mb-2'>already hava an account ? <span className="text-primary"><Link to="/Login">Login here</Link></span></p>
                                </div>
                                {errors && errors.SubmitError && (
                                    <p className="text-danger m-0 mb-3 fs-xs">
                                        {errors.SubmitError}
                                    </p>
                                )}
                                <button className='btn btn-primary w-100 ' onClick={handleSubmit} disabled={isInputs.submitBtn}>{!loading ? "SignUp" : "Submitting"}</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
