import React from 'react'

export const OneTimePassword = () => {
    return (
        <div className='container mt-4'>
            <div className="row justify-content-center">
                <div className="col-12 col-lg-6">
                    <div className="shadow p-5 rounded">
                        <h2 className="text-center mb-4">Sign in to your account </h2>
                        <form>
                            <div class="mb-3">
                                <label for="email" class="form-label">Email OTP</label>
                                <input type="tel" maxLength="6" minLength="6" class="form-control" id="email" aria-describedby="emailHelp" placeholder='One Time Password' /* onChange={(e) => { handleEmail(e) }}*/ />
                                <div class="form-text" id="basic-addon4">Enter your OTP(one time password sent to your email)</div>
                                {/* {error && error.email && (
                                    <p className="text-danger m-0 fs-xs">
                                        {error.email}
                                    </p>
                                )} */}
                            </div>
                            <div class="mb-3">
                                <label for="email" class="form-label">Contact OTP</label>
                                <input type="email" class="form-control" id="email" aria-describedby="emailHelp" placeholder='One Time Password' /* onChange={(e) => { handleEmail(e) }}*/ />
                                <div class="form-text" id="basic-addon4">Enter your OTP(one time password sent to your moblie number)</div>
                                {/* {error && error.email && (
                                    <p className="text-danger m-0 fs-xs">
                                        {error.email}
                                    </p>
                                )} */}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
