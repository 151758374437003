import React, { useEffect, useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { FaStar, FaStarHalfAlt } from "react-icons/fa";
import { FaRegStar } from "react-icons/fa";
import { Link, useParams, useNavigate } from "react-router-dom";
import trainerDefault from "../../../../assets/images/trainerdefault.png";
import { FaIndianRupeeSign } from "react-icons/fa6";
import Select from "react-select";
import { Modal } from "bootstrap"; // Import Bootstrap modal
import {
  CiFacebook,
  CiInstagram,
  CiLinkedin,
  CiPhone,
  CiTwitter,
} from "react-icons/ci";
import RequestDemo from "../../courselists/RequestDemo";
import LoadingScreen from "../../../../utils/loading/LoadingScreen";
import check from "../../../../assets/images/check.png";
import { LiaRupeeSignSolid } from "react-icons/lia";
import { REAPI } from "../../../../serviceLayer/Interceptor";

const SingleCourseLandingPage = () => {
    // Retrieve user data from localStorage (for conditional rendering)
    const LocalStorage = localStorage.getItem("data");
    const parseData = JSON.parse(LocalStorage)
    console.log(parseData&& parseData,"localDAta")

  const [refresh, setRefresh] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const [formReview, setformReview] = useState({
    imageOfStudent: null,
    ratings: 0,
    studentName: "",
    reviewDescription: "",
  });
  const [loadingReviews, setLoadingReviews] = useState(true);
  const [errorReviews, setErrorReviews] = useState(null);
  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    setformReview({
      ...formReview,
      [name]: type === "file" ? files[0] : value,
    });
  };

  const handleRating = (value) => {
    setformReview({ ...formReview, ratings: value });
  };

  const validate = () => {
    const newErrors = {};
    if (!formReview.studentName) newErrors.studentName = "Name is required.";
    if (!formReview.reviewDescription)
      newErrors.reviewDescription = "Description is required.";
    if (formReview.ratings === 0) newErrors.ratings = "Rating is required.";
    return newErrors;
  };

  const handleReviewSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length) {
      setErrors(validationErrors);
      return;
    }

    const data = new FormData();
    data.append("imageOfStudent", formReview.imageOfStudent);
    data.append("ratings", formReview.ratings);
    data.append("studentName", formReview.studentName);
    data.append("reviewDescription", formReview.reviewDescription);
    data.append("courseName", courseName);

    try {
      const response = await REAPI.post(`/reviews/featuredreview`, data);

      if (response.status === 201) {
        setformReview({
          imageOfStudent: null,
          ratings: 0,
          studentName: "",
          reviewDescription: "",
        });
        setErrors({});
        const modalElement = document.getElementById("reviewSuccessModal");
        const modal = new Modal(modalElement);
        modal.show();
      } else {
        console.error("Error submitting review");
      }
    } catch (error) {
      console.error("Network error", error);
    }
  };
  const handleCloseReview = () => {
    window.location.reload();
  };

  const { categoryName, courseName } = useParams();
  const [filteredCourse, setFilteredCourse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [relatedCourse, setRelatedCourse] = useState(null);
  const [reviewsRatings, setReviewRatings] = useState([]);
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    if (courseName) {
      const fetchReviewPerCent = async () => {
        try {
          const { data, status } = await REAPI.get(
            `/reviews/totalandavgcourse/${courseName}`
          );
          if (status === 200) {
            setReviewRatings(data);
          }
        } catch (error) {
          setError(error);
        }
      };
      fetchReviewPerCent();
    }
  }, [courseName]);

  const [trainerDemo, setTrainerDemo] = useState([]);
  const [trainerId, setTrainerId] = useState(null);

  useEffect(() => {
    if (filteredCourse?.id && trainerId) {
      const fetchTrinerDemo = async () => {
        try {
          const { data, status } = await REAPI.get(
            `/current/getTrainer/${filteredCourse?.id}/${trainerId}`
          );
          if (status === 200) {
            setTrainerDemo(data);
          }
        } catch (error) {
          setError(error);
        }
      };
      fetchTrinerDemo();
    }
  }, [filteredCourse?.id, trainerId]);

  const [visibleReviews, setVisibleReviews] = useState(5);

  const handleToggleReviews = () => {
    if (visibleReviews >= reviews.length) {
      setVisibleReviews(5); // Reset to 5 when all reviews are shown
    } else {
      setVisibleReviews((prev) => Math.min(prev + 5, reviews.length)); // Show more reviews, up to the total count
    }
  };

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await REAPI.get(`/course/courses`);
        const coursesData = response.data;

        const currentCourse = coursesData.find(
          (course) =>
            course.categoryName?.toLowerCase().trim() ===
            categoryName?.toLowerCase().trim() &&
            course.courseName?.toLowerCase().trim() ===
            courseName?.toLowerCase().trim()
        );

        setFilteredCourse(currentCourse || null);

        const relatedCoursefiltered = coursesData.filter(
          (course) =>
            course.categoryName?.toLowerCase() ===
            categoryName?.toLowerCase() &&
            course.courseName?.toLowerCase() !== courseName?.toLowerCase()
        );

        setRelatedCourse(relatedCoursefiltered);
      } catch (error) {
        setError("Error fetching coursecs. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchCourses();
  }, [categoryName, courseName]);

  //fetch featured Review api
  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const encodedCourseName = encodeURIComponent(courseName); // Encode course name for safe URL use
        const response = await REAPI.get(
          `/reviews/listReviews?filter={"courseName":"${encodedCourseName}"}`
        );
        setReviews(response.data.reviews);
      } catch (error) {
        setErrorReviews("Error fetching reviews. Please try again later.");
      } finally {
        setLoadingReviews(false);
      }
    };

    fetchReviews();
  }, [courseName, refresh]);


  const [trainer, setTrainer] = useState([]);
  const [selectedTrainer, setSelectedTrainer] = useState("");
  const [slotOptions, setSlotOptions] = useState([]);
  const [sessionOptions, setSessionOptions] = useState([]);
  const [selectSlot, setSelectSlot] = useState("");

  const [slotOptionModal, setSlotOptionModal] = useState([]);
  const [sessionOptionsModal, setSessionOptionsModal] = useState([]);
  const [selectSlotModal, setSelectSlotModal] = useState("");

  useEffect(() => {
    const fetchTrainer = async () => {
      try {
        const response = await REAPI.get(`/current/getTrainers/${courseName}`);
        const data = response.data;
        setTrainer(data);
      } catch (error) {
        console.log("Trainer Loading error :", error.response);
      }
    };
    fetchTrainer();
  }, [courseName]);

  const trainerOptions = trainer.map((trainers) => ({
    value: trainers.trainerName,
    label: trainers.trainerName,
    id: trainers.id,
  }));

  const handleSelectChangePopup = (selectedOption) => {
    const availableTrainerCourses = trainer.find(
      (train) => train.id === selectedOption.id
    )?.Courses[0]?.AvailableTrainerCourses;

    if (availableTrainerCourses) {
    } else {
    }

    if (
      selectedOption &&
      availableTrainerCourses &&
      availableTrainerCourses.availableTrainerId === selectedOption.id
    ) {
      const availableBatchOptions =
        availableTrainerCourses.AvailableBatches.map((batch) => ({
          label: `${batch.from} - ${batch.to}`,
          value: `${batch.from} - ${batch.to}`,
        }));

      const sessionOptions = availableTrainerCourses.sessions.map(
        (session, index) => ({
          id: index + 1,
          label: `${session.name}`,
          price: ` ${session.price}`,
          feeType: `${availableTrainerCourses.feetype}`,
          value: session.price,
        })
      );

      setSlotOptionModal(availableBatchOptions);
      setSessionOptionsModal(sessionOptions);

      setStaticInput({
        ...staticInput,
        trainer: selectedOption.value,
        availableTrainerId: selectedOption.id,
      });
      setSelectedTrainer(selectedOption);
    } else {
      setFormData({
        ...formData,
      });
      setSlotOptions([]);
    }
  };

  const handleSelectChange = (selectedOption) => {
    const availableTrainerCourses = trainer.find(
      (train) => train.id === selectedOption.id
    )?.Courses[0]?.AvailableTrainerCourses;

    if (availableTrainerCourses) {
    } else {
    }

    if (
      selectedOption &&
      availableTrainerCourses &&
      availableTrainerCourses.availableTrainerId === selectedOption.id
    ) {
      const availableBatchOptions =
        availableTrainerCourses.AvailableBatches.map((batch) => ({
          label: `${batch.from} - ${batch.to}`,
          value: `${batch.from} - ${batch.to}`,
        }));

      const sessionOptions = availableTrainerCourses.sessions.map(
        (session, index) => ({
          id: index + 1,
          label: `${session.name}`,
          price: ` ${session.price}`,
          feeType: `${availableTrainerCourses.feetype}`,
          value: session.price,
        })
      );

      setSlotOptions(availableBatchOptions);
      setSessionOptions(sessionOptions);

      setFormData({
        ...formData,
        trainer: selectedOption.value,
        availableTrainerId: selectedOption.id,
      });
      setSelectedTrainer(selectedOption);
    } else {
      setFormData({
        ...formData,
      });
      setSlotOptions([]);
    }
  };

  const handleAvailabaleBatches = (selectedOption) => {
    setFormData({
      ...formData,
      availableBatchess: selectedOption.value,
    });
  };

  const handleAvailableBatchesPopup = (selectedOption) => {
    setStaticInput({ ...staticInput, availableBatchess: selectedOption.value });
  };

  const [formData, setFormData] = useState({
    name: parseData && parseData.FullName || "",
    email: parseData &&  parseData.user || "",
    contact: parseData && parseData.phonenumber || "",
    trainer: "",
    availableTrainerId: "",
    availableBatchess: [],
    city: "",
    Course: { courseName },
    price: "",
  });

  const [staticInput, setStaticInput] = useState({
    name: "",
    email: "",
    contact: "",
    trainer: "",
    availableTrainerId: "",
    availableBatchess: [],
    city: "",
    Course: { courseName },
    price: "",
  });

  useEffect(() => {
    const fetchSlots = async () => {
      if (selectedTrainer) {
        try {
          const availableSlot = trainer.find(
            (item) => item.id === selectedTrainer.id
          );
          setSelectSlotModal(availableSlot.availableBatchess);
          setSelectSlot(availableSlot.availableBatchess);
          setStaticInput({
            ...staticInput,
            availableBatchess: availableSlot.availableBatchess,
          });
          setFormData({
            ...formData,
            availableBatchess: availableSlot.availableBatchess,
          });
        } catch (error) {
          console.log(error, "slots error");
        }
      }
    };
    fetchSlots();
  }, [selectedTrainer]);

  const [selectedPrice, setSelectedPrice] = useState(null);
  const [errors, setErrors] = useState({});
  const [errorsPopUp, setErrorsPopUp] = useState({});

  const handleInputChange = (e, fromtype) => {
    const { id, value } = e.target;
    if (id === "contact") {
      if (!/^\d*$/.test(value) || value.length > 10) {
        return;
      }
    }
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));

    if (fromtype === "PopUp") {
      setErrorsPopUp((prevErrors) => ({
        ...prevErrors,
        [id]: "",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [id]: "",
      }));
    }
  };

  const handlePopUpChange = (e, fromtypes) => {
    const { id, value } = e.target;
    if (id === "contact") {
      if (!/^\d*$/.test(value) || value.length > 10) {
        return;
      }
    }

    setStaticInput((prevData) => ({
      ...prevData,
      [id]: value,
    }));

    setErrorsPopUp((prevErrors) => ({
      ...prevErrors,
      [id]: "",
    }));
  };

  const handleSlotChange = (e) => {
    const selectedSlot = JSON.parse(e.target.value); // Parse the JSON string from the radio button
    setFormData({
      ...formData,
      slot: [
        {
          label: selectedSlot.label, // Set the label
          feeType: selectedSlot.feeType, // Set the feeType
          price: selectedSlot.price || "Free", // Set the price or "Free" if not provided
        }
      ]
    });
  };
  const handleSlotChangePopUp = (e) => {
    const selectedSlot = JSON.parse(e.target.value);
    setStaticInput({
      ...staticInput,
      slot: [
        {
          label: selectedSlot.label, // Set the label
          feeType: selectedSlot.feeType, // Set the feeType
          price: selectedSlot.price || "Free", // Set the price or "Free" if not provided
        }
      ]
    });
  };

  const validateForm = (formData) => {
    let formErrors = {};

    // Validate name
    if (!formData.name) {
      formErrors.name = "Name is required";
      setErrors(formErrors);

      return false;
    } else if (formData.name.length < 3) {
      formErrors.name = "Name must be at least 3 characters long";
      setErrors(formErrors);

      return false;
    }

    if (!formData.email) {
      formErrors.email = "Email is required";
      setErrors(formErrors);

      return false;
    } else if (!/^[a-zA-Z0-9._%+-]{1,}@[a-zA-Z0-9.-]{1,}\.[a-zA-Z]{2,}$/.test(formData.email)) {
      formErrors.email = "Email address is invalid";  
      setErrors(formErrors);

      return false;
    }
    // Validate contact number
    if (!formData.contact) {
      formErrors.contact = "Contact number is required";
      setErrors(formErrors);

      return false;
    } else if (formData.contact.length < 10) {
      formErrors.contact = "Contact number must be at least 10 digits";
      setErrors(formErrors);

      return false;
    }

    // Validate city
    if (!formData.city) {
      formErrors.city = "Please select a city";
      setErrors(formErrors);

      return false;
    }

    // Validate trainer selection
    if (!formData.trainer) {
      formErrors.trainer = "Please select a trainer";
      setErrors(formErrors);

      return false;
    }

    // Validate available batches
    if (!formData.availableBatchess) {
      formErrors.availableBatchess = "Please select available batches";
      setErrors(formErrors);

      return false;
    }

    // Validate slot selection
    if (!formData.slot) {
      formErrors.slot = "Please select a slot";
      setErrors(formErrors);

      return false;
    }

    // Set errors and return validation status
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return false; // Validation failed
    }

    setErrors({}); // Clear errors if validation passed
    return true; // Validation passed
  };

  const validateFormpopup = (staticInput) => {
    let formErrorspopup = {};

    if (!staticInput.name) {
      formErrorspopup.name = "Name is required";
    } else if (staticInput.name.length < 3) {
      formErrorspopup.name = "Name must be at least 3 characters long";
    }
    if (!staticInput.email) {
      formErrorspopup.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(staticInput.email)) {
      formErrorspopup.email = "Invalid email format";
    }

    if (!staticInput.contact) {
      formErrorspopup.contact = "Contact number is required";
    } else if (staticInput.contact.length < 10) {
      formErrorspopup.contact = "Contact number must be at least 10 digits";
    }

    if (!staticInput.city) {
      formErrorspopup.city = "City is required";
    }

    if (!staticInput.availableBatchess) {
      formErrorspopup.availableBatchess = "Please select available batches";
    }

    if (!staticInput.slot) {
      formErrorspopup.slot = "Slot selection is required";
    }
    console.log("Validation Errors: ", formErrorspopup);
    setErrorsPopUp(formErrorspopup);

    // Return true if there are no errors
    return Object.keys(formErrorspopup).length === 0;
  };

  // payment function
  
  const handlepay = async(e)=>{
    e.preventDefault();
    const validationErrors = validateForm(formData);
    if (Object.keys(validationErrors).length > 0) {
      setError(validationErrors);
      return;
    }
    try {
      // navigate(`/authentication/Signup`);
      // const response = await REAPI.post(
      //   `/enroll/userEnrollment/${filteredCourse?.courseName}`,
      //   formData
      // );
      setFormData({
        name: "",
        email: "",
        contact: "",
        city: "",
        trainer: "",
        slot: "",
        availableBatchess: "",
        Course: "",
      });
      setErrors({});

      
      // setTimeout(() => {
      //   navigate(-1);
      // }, 3000);
  }catch(error){
    console.log("payment Error",error)
  }
}
const handleSubmit = async (e) => {
  console.log("firstsubmit")
  e.preventDefault();
  const validationErrors = validateForm(formData);
  if (Object.keys(validationErrors).length > 0) {
    setError(validationErrors);
    return;
  }

  try {
    // const response = await REAPI.post(
    //     `/enroll/userEnrollment/${filteredCourse?.courseName}`,
    //     formData
    //   );
    setFormData({
      name: "",
      email: "",
      contact: "",
      city: "",
      trainer: "",
      slot: "",
      availableBatchess: "",
      Course: "",
    });
    navigate(`/Signup`);
    setErrors({});

    
    // setTimeout(() => {
    //   navigate(-1);
    // }, 3000);
  } catch (error) {
    console.log("There was an error submitting the form:", error);
  }
};

  const handleSubmitPopup = async (e) => {
    e.preventDefault();
    const isValid = validateFormpopup(staticInput);
    if (!isValid) {
      return; // Exit if there are validation errors
    }

    try {
      const response = await REAPI.post(
        `/enroll/userEnrollment/${filteredCourse?.courseName}`,
        staticInput
      );
      setStaticInput({
        name: "",
        email: "",
        contact: "",
        city: "",
        trainer: "",
        slot: "",
        availableBatchess: "",
        Course: "",
      });
      setErrorsPopUp({});
      const modalElement = document.getElementById("exampleModalToggle2");
      const modalInstance = Modal.getInstance(modalElement);
      if (modalInstance) {
        modalInstance.hide(); // Close modal programmatically
      }
      document.querySelectorAll(".modal-backdrop").forEach((el) => el.remove());
      navigate(`/courselist/course/${categoryName}/${courseName}/thankyou`);
    } catch (error) {
      console.log("There was an error submitting the form:", error);
    }
  };

  const [showMore, setShowMore] = useState(false);
  const handleToggle = () => {
    setShowMore(!showMore);
  };

  const [showMoreImage, setShowMoreImage] = useState(false);

  const handleToggleImage = () => {
    setShowMoreImage(!showMoreImage);
  };

  if (loading)
    return (
      <p>
        <LoadingScreen />
      </p>
    );
  if (error) return <p>{error}</p>;
  if (!filteredCourse) return <p>Course not found</p>;

  //code start for wht you will learn i am getting from the api
  function removeInlineStyles(html) {
    return html.replace(/style="[^"]*"/g, "");
  }

  const cleanedHTML = removeInlineStyles(filteredCourse.textWhatToLearn);
  const [beforeList, afterList] = cleanedHTML.split(/<ul>|<\/ul>/);
  const listItems =
    cleanedHTML
      .match(/<li>(.*?)<\/li>/g)
      ?.map((item) => item.replace(/<\/?li>/g, "")) || [];

  const initialItems = listItems.slice(0, 0);
  const TIME_UNITS = [
    { label: "second", seconds: 1 },
    { label: "minute", seconds: 60 },
    { label: "hour", seconds: 3600 },
    { label: "day", seconds: 86400 },
  ];

  const timeAgo = (timestamp) => {
    const now = new Date();
    const reviewDate = new Date(timestamp);
    const secondsElapsed = Math.floor((now - reviewDate) / 1000);

    for (let i = TIME_UNITS.length - 1; i >= 0; i--) {
      const { label, seconds } = TIME_UNITS[i];
      const count = Math.floor(secondsElapsed / seconds);
      if (count > 0) {
        return `${count} ${label}${count !== 1 ? "s" : ""} ago`;
      }
    }

    return "just now";
  };
  const getVideoEmbedUrl = (url) => {
    const urlObj = new URL(url);
    const fileIdPattern = /\/d\/(.+?)\/|\/file\/d\/(.+?)\//;

    // Check if it's a Google Drive link
    if (urlObj.hostname === 'drive.google.com' && fileIdPattern.test(url)) {
      const fileId = url.match(fileIdPattern)[1] || url.match(fileIdPattern)[2];
      return `https://drive.google.com/uc?id=${fileId}`;
    }

    // Check for standard YouTube link
    if (urlObj.hostname === 'www.youtube.com' || urlObj.hostname === 'youtu.be') {
      const params = new URLSearchParams(urlObj.search);
      return params.has('v')
        ? `https://www.youtube.com/embed/${params.get('v')}`
        : `https://www.youtube.com/embed/${urlObj.pathname.split('/').pop()}`;
    }

    return '';
  };

  const stopVideo = () => {
    const videoElement = document.querySelector('#exampleModalToggle1 iframe.video');
    if (videoElement) {
      videoElement.src = '';
    }
  };

  // Function to set the video source on open
  const startVideo = () => {
    const videoElement = document.querySelector('#exampleModalToggle1 iframe.video');
    if (videoElement) {
      videoElement.src = trainerDemo?.Courses?.[0]?.AvailableTrainerCourses?.demoLink
        ? getVideoEmbedUrl(trainerDemo.Courses[0].AvailableTrainerCourses.demoLink)
        : ''; // Set src to start the video
    }
  };

  // Close button handler to stop video and close modal
  const handleClosePopUpModel = () => {
    let currentModal = new Modal(document.getElementById("exampleModalToggle1"));
    currentModal.hide();
    document.querySelectorAll(".modal-backdrop").forEach((el) => el.remove());
    stopVideo(); // Stop the video when closing the modal
  };


  return (
    <div>
      <div className="container-fluid p-0">
        <div className="course-bg-banner">
          <div className="banner-courselanding">
            <div className="padding_css">
              <p className="fs-s banner_pragraph_color text-white">
                Home <IoIosArrowForward /> Course
              </p>

              <div className="row">
                <div className="col-lg-7 banner_text">
                  <h2 className="fs-44 fw-700 text-white">
                    {filteredCourse?.courseName}
                  </h2>
                  <p className="text-white">{filteredCourse.courseHeading}</p>
                  <div className="d-flex align-items-center flex-wrap">
                    <p className="star_Css">
                      {Array.from({ length: 5 }, (_, index) => {
                        const fullStar =
                          index + 1 <= Math.floor(filteredCourse?.courseRating);
                        const halfStar =
                          index + 1 >
                          Math.floor(filteredCourse?.courseRating) &&
                          index < filteredCourse?.courseRating;

                        if (fullStar) {
                          return (
                            <FaStar
                              key={index}
                              className="mb-1  review_color"
                            />
                          );
                        } else if (halfStar) {
                          return (
                            <FaStarHalfAlt
                              key={index}
                              className="mb-1  review_color"
                            />
                          );
                        } else {
                          return (
                            <FaRegStar
                              key={index}
                              className="mb-1  review_color"
                            />
                          );
                        }
                      })}
                    </p>
                    <p className="fs-m fw-500 rating_css text-white">
                      <span className="">
                        {filteredCourse.ratingsCount > 0
                          ? filteredCourse.ratingsCount
                          : 0}{" "}
                        Ratings
                      </span>
                    </p>
                    <p className="fs-m students_css fw-500 text-white">
                      {filteredCourse.studentsCount > 0
                        ? filteredCourse.studentsCount
                        : 0}{" "}
                      Students
                    </p>
                  </div>
                  <div className="mt-4">
                    <RequestDemo />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* --- */}
        <div className="container">
          <div className="row mt-3">
            <div className="col-lg-8">
              <div className="what_you_learn mt-4">
                <h4 className="fw-700 fs-24">Instructor</h4>
                <div className="row">
                  {trainer.length > 0 ? (
                    trainer.map((item, index) => (
                      <div
                        className="col-lg-6 col-md-6 col-sm-12 mt-4"
                        key={index}
                      >
                        <div className="trainer_box flex-column align-items-center justify-content-between">
                          <div className="text-center">
                            <div className="photo_bg">
                              <img
                                src={
                                  item.trainerImage
                                    ? item.trainerImage
                                    : trainerDefault
                                }
                                className="rounded w-100 h-100"
                                alt="trainer"
                              />
                            </div>
                            <h5 className="mt-0">{item.trainerName}</h5>
                          </div>
                          <div className="row">
                            {/* First Column */}
                            <div className="col-md-12 text-start">
                              <ul className="ms-4 mb-0">
                                <tr className="banner_pragraph_color fs-s fw-500 mt-3">
                                  <td className="fw-700 text-dark">Timing</td>
                                  <span className="ms-3">:</span>
                                  <td className="px-3">
                                    {item?.Courses[0]?.AvailableTrainerCourses?.AvailableBatches?.slice(
                                      0,
                                      4
                                    ).map((session, index) => (
                                      <span key={index}>
                                        {session.from} - {session.to}
                                        {index < 3 && ", "}{" "}
                                        {/* Adjusted index for comma */}
                                      </span>
                                    ))}
                                  </td>
                                </tr>

                                <li className="banner_pragraph_color fs-s fw-500 mt-1">
                                  <span className="fw-700 text-dark">
                                    Speaks
                                  </span>
                                  <span className="ms-3">:</span>
                                  <span className="px-3">
                                    {item?.language?.map((language, index) => (
                                      <span key={index}>
                                        {language}
                                        {index < item?.language.length - 1
                                          ? ", "
                                          : ""}
                                      </span>
                                    ))}
                                  </span>
                                </li>
                              </ul>

                              <div className="text-center pricing mb-3">
                                <span className="ms-2 ">
                                  {item?.Courses[0]?.AvailableTrainerCourses?.courseType === "Paid Course" ? (
                                    <div className="actualAmount">
                                      <FaIndianRupeeSign />
                                      {item?.Courses[0]?.AvailableTrainerCourses?.sessions[0]?.originalPrice}
                                      {/* Only display the fee type without session name if feetype is 'course' */}
                                      {item?.Courses[0]?.AvailableTrainerCourses?.feetype?.toLowerCase() === "course" ? (
                                        <> / {item?.Courses[0]?.AvailableTrainerCourses?.feetype}</>
                                      ) : (
                                        <>
                                          / {item?.Courses[0]?.AvailableTrainerCourses?.sessions[0]?.name}{" "}
                                          {item?.Courses[0]?.AvailableTrainerCourses?.feetype?.toLowerCase() === "session"
                                            ? "session"
                                            : item?.Courses[0]?.AvailableTrainerCourses?.feetype?.toLowerCase() === "month"
                                              ? "month"
                                              : ""}
                                        </>
                                      )}
                                    </div>
                                  ) : (
                                    <div className="freecourse">
                                      Free Course
                                    </div>
                                  )}
                                </span>
                                <li className="">
                                  <span className="price-background mt-2">
                                    {item?.Courses[0]?.AvailableTrainerCourses?.courseType === "Paid Course" ? (
                                      <>
                                        {item?.Courses[0]?.AvailableTrainerCourses?.sessions?.length > 0 ? (
                                          <span>
                                            <FaIndianRupeeSign />
                                            {item.Courses[0].AvailableTrainerCourses.sessions[0].price}
                                            {/* Display only the fee type without session name if feetype is 'course' */}
                                            {item.Courses[0].AvailableTrainerCourses.feetype?.toLowerCase() === "course" ? (
                                              <> / {item.Courses[0].AvailableTrainerCourses.feetype}</>
                                            ) : (
                                              <>
                                                / {item.Courses[0].AvailableTrainerCourses.sessions[0].name}{" "}
                                                {item.Courses[0].AvailableTrainerCourses.feetype}
                                              </>
                                            )}
                                          </span>
                                        ) : (
                                          <span>No sessions available</span>
                                        )}
                                      </>
                                    ) : (
                                      <span>Free Course</span>
                                    )}
                                  </span>
                                </li>
                              </div>
                            </div>
                          </div>
                          <div className="text-center mb-0 mt-3 ">
                            <button
                              className="  slot-font"
                              data-bs-target="#exampleModalToggle2"
                              data-bs-toggle="modal"
                              onClick={() => handleSelectChangePopup(item)}
                            >
                              Enroll Now
                            </button>
                            <button
                              type="button"
                              className=" slot-font1"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModalToggle1"
                              onClick={() => {
                                setTrainerId(item?.id);
                                startVideo();
                              }}
                            >
                              View Demo
                            </button>
                            {trainerDemo && (
                              <div
                                className="modal fade text-left"
                                id="exampleModalToggle1"
                                data-bs-backdrop="static"
                                data-bs-keyboard="false"
                                tabIndex="-1"
                                aria-labelledby="exampleModalToggleLabel1"
                                aria-hidden="true"
                              >
                                <div className="modal-dialog modal-lg">
                                  <div className="modal-content">
                                    <div className="modal-header">
                                      <h2
                                        className="modal-title fs-5"
                                        id="exampleModalToggleLabel1"
                                      >
                                        Trainer Demo
                                      </h2>
                                      <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        onClick={handleClosePopUpModel}
                                      ></button>
                                    </div>
                                    <div className="modal-body">
                                      <div className="row">
                                        {/* Video on the left side */}
                                        <div className="col-md-6">
                                          <iframe
                                            className="video"
                                            title="Video player"
                                            sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                                            width="100%"
                                            height="100%"
                                            src={trainerDemo?.Courses?.[0]?.AvailableTrainerCourses?.demoLink ? getVideoEmbedUrl(trainerDemo.Courses[0].AvailableTrainerCourses.demoLink) : ''}
                                            frameBorder="0"
                                            allowFullScreen
                                          ></iframe>
                                        </div>
                                        {/* Input form on the right side */}
                                        <div className="col-md-6">
                                          <p>Description :</p>
                                          <p>
                                            {
                                              trainerDemo?.Courses?.[0]
                                                ?.AvailableTrainerCourses
                                                ?.trainerCourseDescription
                                            }
                                            {console.log(
                                              trainerDemo?.Courses?.[0]
                                                ?.AvailableTrainerCourses
                                                ?.trainerCourseDescription
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <div className="card-body bg_white">
                                        <div className="table-responsive table-scroll table-container table-card border-0 bg_white">
                                          <table className="bg_white table table-centered align-middle table-nowrap equal-cell-table table-hover text-start">
                                            <thead className="tableheading">
                                              <tr>
                                                <th
                                                  scope="col"
                                                  className="fs-13 lh-xs fw-600"
                                                >
                                                  Timing
                                                </th>
                                                <th
                                                  scope="col"
                                                  className="fs-13 lh-xs fw-600"
                                                >
                                                  Days
                                                </th>
                                                <th
                                                  scope="col"
                                                  className="fs-13 lh-xs fw-600"
                                                >
                                                  Price
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {trainerDemo?.Courses?.[0]?.AvailableTrainerCourses?.AvailableBatches?.map(
                                                (batch, index) => (
                                                  <tr key={index}>
                                                    {/* Timing */}
                                                    <td className="fs-13 black_300 fw-500 lh-xs bg_light">
                                                      {batch?.from} -{" "}
                                                      {batch?.to}
                                                    </td>
                                                    {/* Days */}
                                                    <td className="fs-13 black_300 fw-500 lh-xs bg_light">
                                                      {trainerDemo?.Courses?.[0]?.AvailableTrainerCourses?.days?.join(
                                                        ", "
                                                      )}
                                                    </td>
                                                    {/* Price */}
                                                    <td className="fs-13 black_300 fw-500 lh-xs bg_light">
                                                      {trainerDemo?.Courses?.[0]
                                                        ?.AvailableTrainerCourses
                                                        ?.courseType ===
                                                        "Paid Course" ? (
                                                        trainerDemo?.Courses?.[0]?.AvailableTrainerCourses?.sessions?.map(
                                                          (
                                                            session,
                                                            sessionIndex
                                                          ) => (
                                                            <div
                                                              key={sessionIndex}
                                                            >
                                                              {/* <FaIndianRupeeSign /> <span className="actualAmount">{session.originalPrice}</span> */}
                                                              <FaIndianRupeeSign />{" "}
                                                              {session.price}/
                                                              {session.name}{" "}
                                                              {
                                                                trainerDemo
                                                                  ?.Courses?.[0]
                                                                  ?.AvailableTrainerCourses
                                                                  ?.feetype
                                                              }
                                                            </div>
                                                          )
                                                        )
                                                      ) : (
                                                        <span>Free Course</span>
                                                      )}
                                                    </td>
                                                  </tr>
                                                )
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="modal-footer">
                                      <button
                                        type="button"
                                        className="btn bg-teks-gradient-btn text-white fs-12"
                                        data-bs-dismiss="modal"
                                        onClick={handleClosePopUpModel}
                                      >
                                        Close
                                      </button>
                                      <button
                                        type="button"
                                        className="btn bg-teks-gradient-btn text-white fs-12"
                                        data-bs-target="#exampleModalToggle2"
                                        data-bs-toggle="modal"
                                        onClick={() => {
                                          let currentModal = new Modal(
                                            document.getElementById(
                                              "exampleModalToggle1"
                                            )
                                          );
                                          currentModal.hide();
                                          document
                                            .querySelectorAll(".modal-backdrop")
                                            .forEach((el) => el.remove());
                                          let newModal = new Modal(
                                            document.getElementById(
                                              "exampleModalToggle2"
                                            )
                                          );
                                          newModal.show();
                                          handleSelectChangePopup(trainerDemo);
                                        }}
                                      >
                                        Enroll Now
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}

                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="text-center">No trainers available</p>
                  )}
                </div>
              </div>

              {/* ---- */}
              <div className="what_you_learn mt-4">
                <h4 className="fw-700 fs-24 ">What you'll learn</h4>

                <div className="banner_pragraph_color fs-18">
                  <div
                    dangerouslySetInnerHTML={{ __html: beforeList.trim() }}
                  />
                </div>
                <ul className="notes-list-style-1 banner_pragraph_color fs-18">
                  {(showMore ? listItems : initialItems).map((item, index) => (
                    <li key={index}>
                      <i className="tick_mark fs-18 banner_pragraph_color"></i>
                      {item}
                    </li>
                  ))}
                </ul>

                {/* Show More / Show Less button */}
                <div className="notes-show-more-btn" onClick={handleToggle}>
                  {showMore ? "Show Less" : "Show More"}
                </div>
              </div>

              {/* --- */}
              <div className="what_you_learn mt-4">
                <div className="row">
                  <div className="col-lg-12">
                    <h4 className="fw-700 fs-24">Requirements</h4>
                    <hr></hr>
                    <ul className="notes-list-style-1">
                      {filteredCourse.requirement &&
                        filteredCourse.requirement.length > 0 ? (
                        filteredCourse.requirement.map((req, index) => (
                          <li
                            key={index}
                            className="banner_pragraph_color fs-18"
                          >
                            <i className="tick_mark fs-18"></i>
                            {req.name}
                          </li>
                        ))
                      ) : (
                        <li className="banner_pragraph_color fs-18">
                          <i className="tick_mark fs-18"></i>
                          No specific requirements.
                        </li>
                      )}
                    </ul>
                  </div>
                  <div className="col-lg-12 mt-3">
                    <h4 className="fw-700 fs-24">Description</h4>
                    <hr></hr>
                    <ul className="notes-list-style-1">
                      {filteredCourse.description &&
                        filteredCourse.description.length > 0 ? (
                        filteredCourse.description.map((desc, index) => (
                          <li
                            key={index}
                            className="banner_pragraph_color fs-18"
                          >
                            <i className="tick_mark fs-18"></i>
                            {desc.name}
                          </li>
                        ))
                      ) : (
                        <li className="banner_pragraph_color fs-18">
                          <i className="tick_mark fs-18"></i>
                          No specific description available.
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
              {/* --- */}
              <div className="what_you_learn mt-4">
                <h4 className="fw-700 fs-24 mb-3 ms-3">Rating</h4>
                <div className="col-lg-6 ps-3 mb-2 py-2">
                  <div className="rating-text">
                    {[1, 2, 3, 4, 5].map((value) => (
                      <span
                        key={value}
                        onClick={() => handleRating(value)}
                        style={{ cursor: "pointer" }}
                      >
                        {value <= formReview.ratings ? (
                          <FaStar className="review_color  ms-2 fs-24" />
                        ) : (
                          <FaRegStar className="review_border  ms-2 fs-24" />
                        )}
                      </span>
                    ))}
                  </div>
                  {errors.ratings && (
                    <p className="text-danger fs-xs">{errors.ratings}</p>
                  )}
                </div>

                <form onSubmit={handleReviewSubmit}>
                  <div className="col-lg-8">
                    <div className="form-group mb-3 align-items-center">
                      <label className="form-label fs-s fw-medium black_300">
                        Name
                      </label>
                      <input
                        type="text"
                        name="studentName"
                        className={`form-control input_bg_color ${errors.studentName ? "error-input" : ""
                          }`}
                        placeholder="Enter your name"
                        value={formReview.studentName}
                        onChange={handleChange}
                        required
                      />
                      <div className="response" style={{ height: "8px" }}>
                        {errors.studentName && (
                          <p className="text-danger m-0 fs-xs">
                            {errors.studentName}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-8">
                    <div className="form-label fs-s fw-medium black_300">
                      <label htmlFor="Textarea">Description</label>
                      <textarea
                        name="reviewDescription"
                        className={`form-control fs-s bg-form text_color input_bg_color ${errors.reviewDescription ? "is-invalid" : ""
                          }`}
                        id="Textarea"
                        rows="3"
                        placeholder="Enter here ..."
                        onChange={handleChange}
                        value={formReview.reviewDescription}
                      ></textarea>
                      <div style={{ height: "8px" }}>
                        {errors.reviewDescription && (
                          <p className="text-danger m-0 fs-xs">
                            {errors.reviewDescription}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-8">
                    <div className="mb-3">
                      <label
                        htmlFor="imageOfStudent"
                        className="form-label fs-s fw-medium black_300"
                      >
                        Image<span className="text-danger"></span>
                      </label>
                      <input
                        type="file"
                        name="imageOfStudent"
                        className={`form-control input_bg_color ${errors.imageOfStudent ? "error-input" : ""
                          }`}
                        id="imageOfStudent"
                        onChange={handleChange}
                      />
                      <div className="response" style={{ height: "8px" }}>
                        {errors.imageOfStudent && (
                          <p className="text-danger m-0 fs-xs">
                            {errors.imageOfStudent}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div>
                    <button type="submit" className="rbt-btn btn-sm ms-3">
                      Submit
                    </button>
                  </div>
                </form>
                <div
                  className="modal fade"
                  id="reviewSuccessModal"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content teks_bg_blue">
                      <div className="modal-body text-center">
                        <img
                          src={check}
                          className="img-fluid h-25 w-25"
                          alt=""
                        />
                        <h4 className="text-white mt-3">Thank You!</h4>
                        <p className="text-white fs-13 fw-400">
                          Your review has been submitted successfully.
                        </p>
                      </div>
                      <div className="d-flex justify-content-end me-2 mb-2">
                        <button
                          type="button"
                          className="btn btn-outline-light"
                          data-bs-dismiss="modal"
                          onClick={handleCloseReview}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="what_you_learn mt-4">
                <h4 className="fw-700 fs-24">Review</h4>
                <hr />
                <div className="row align-items-center">
                  <div className="col-lg-3 col-sm-12">
                    <div className="rating-box">
                      <h5 className="fs-50 fw-700">
                        {reviewsRatings[0]?.averageRating || 0}
                      </h5>
                      <div>
                        {[...Array(5)].map((_, index) => {
                          const averageRating =
                            reviewsRatings[0]?.averageRating || 0;
                          const filledStars = Math.floor(averageRating);
                          const hasPartialStar = averageRating % 1 > 0;
                          const fillPercentage = (averageRating % 1) * 100;

                          if (index < filledStars) {
                            return (
                              <FaStar
                                key={index}
                                className="review_color fs-18"
                              />
                            );
                          } else if (index === filledStars && hasPartialStar) {
                            return (
                              <div
                                style={{
                                  position: "relative",
                                  display: "inline-block",
                                }}
                                key={index}
                              >
                                <FaRegStar className="review_border fs-18" />
                                <FaStar
                                  className="review_color fs-18"
                                  style={{
                                    position: "absolute",
                                    top: 5,
                                    left: 0,
                                    clipPath: `inset(0 ${100 - fillPercentage
                                      }% 0 0)`,
                                  }}
                                />
                              </div>
                            );
                          } else {
                            return (
                              <FaRegStar
                                key={index}
                                className="review_border fs-18 fw-700"
                              />
                            );
                          }
                        })}
                      </div>
                      <h6 className="review_color mt-2">Course Rating</h6>
                    </div>
                  </div>
                  <div className="col-lg-9 col-md-12 col-sm-12">
                    <div className="review-wrapper flex-column d-flex align-items">
                      {[5, 4, 3, 2, 1].map((star, idx) => (
                        <div className="single-progress-bar" key={star}>
                          <div className="rating-text">
                            {[...Array(5)].map((_, starIndex) =>
                              starIndex < star ? (
                                <FaStar
                                  key={starIndex}
                                  className="review_color fs-18"
                                />
                              ) : (
                                <FaRegStar
                                  key={starIndex}
                                  className="review_border fs-18 fw-700"
                                />
                              )
                            )}
                          </div>
                          <div className="progress">
                            <div
                              className="progress-bar"
                              style={{
                                width: `${reviewsRatings[0]?.[
                                  `percentageRating${star}`
                                ] || 0
                                  }%`,
                              }}
                              role="progressbar"
                              aria-valuenow={
                                reviewsRatings[0]?.[
                                `percentageRating${star}`
                                ] || 0
                              }
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                          <span className="value-text">
                            {reviewsRatings[0]?.[`percentageRating${star}`] ||
                              0}
                            %
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              {/* -- */}
              <div className="what_you_learn mt-4">
                <h4 className="fw-700 fs-24">Featured Reviews</h4>
                <hr />
                {reviews?.length > 0 ? (
                  reviews.slice(0, visibleReviews).map((review) => (
                    <div key={review.id}>
                      <div className="row text_center">
                        <div className="col-lg-2 responsive-w-25">
                          <img
                            src={review.imageOfStudent || trainerDefault}
                            className="w-100 "
                            alt={review.studentName}
                          />
                        </div>
                        <div className="col-lg-10">
                          <h5 className="fw-600">{review.studentName}</h5>
                          <div>
                            {[...Array(review.ratings)].map((_, i) => (
                              <FaStar key={i} className="review_color fs-18" />
                            ))}
                          </div>
                          <p className="mt-2">{review.reviewDescription}</p>
                          <p className="text-muted fs-14">
                            {timeAgo(review.createdAt)}
                          </p>
                        </div>
                      </div>
                      <hr />
                    </div>
                  ))
                ) : (
                  <p>No reviews available.</p>
                )}
                <div
                  className="notes-show-more-btn active"
                  onClick={handleToggleReviews}
                >
                  {visibleReviews >= reviews?.length ? "Show Less" : "Show More"}
                </div>
              </div>

              {/* ---- */}
            </div>
            <div className="col-lg-4 mt-5 ">
              <div className="what_you_learn topMargin sticky-top">
                <div className="">
                  <h5>Fill out the form below and we'll respond promptly</h5>
                  <form className="course-enrollment-form formlistdesign">
                    <div className="form-group mb-3 align-items-center">
                      <label className="fw-500 text-dark me-3">Name </label>
                      <input
                        type="text"
                        className={
                          errors && errors.name
                            ? "form-control input_bg_color error-input"
                            : "form-control input_bg_color " || parseData && parseData.FullName ?"form-control input_bg_color":"form-control input_bg_color  cursor-not-allowed"
                        }
                        
                        disabled={parseData && parseData.FullName}
                        id="name"
                        placeholder="Enter your name"
                        value={formData.name}
                        onChange={(e) => handleInputChange(e, "NotPopUp")}
                        required
                        onKeyPress={(e) => {
                          if (!/^[a-zA-Z\s]*$/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                      <div className="response" style={{ height: "8px" }}>
                        {errors && errors.name && (
                          <p className="text-danger m-0 fs-xs">{errors.name}</p>
                        )}
                      </div>
                    </div>

                    <div className="form-group mb-3 align-items-center">
                      <label
                        className="fw-500 text-dark me-3"
                        style={{ minWidth: "100px" }}
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        className={
                          errors && errors.email
                            ? "form-control input_bg_color error-input"
                            : "form-control input_bg_color " || parseData && parseData.user ?"form-control input_bg_color":"form-control input_bg_color cursor-not-allowed"
                        }
                        id="email"
                        placeholder="Enter your email"
                        value={formData.email}
                        disabled={parseData && parseData.user}
                        onChange={(e) => handleInputChange(e, "NotPopUp")}
                        required
                      />
                      <div className="response" style={{ height: "8px" }}>
                        {errors && errors.email && (
                          <p className="text-danger m-0 fs-xs">
                            {errors.email}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="form-group mb-3 align-items-center">
                      <label
                        className="fw-500 text-dark me-3"
                        style={{ minWidth: "100px" }}
                      >
                        Contact{" "}
                      </label>
                      <input
                        type="tel"
                        className={
                          errors && errors.contact
                            ? "form-control input_bg_color error-input"
                            : "form-control input_bg_color " || parseData && parseData.phonenumber ?"form-control input_bg_color":"form-control input_bg_color cursor-not-allowed"
                        }
                        id="contact"
                        placeholder="Enter your contact number"
                        value={formData.contact}
                        disabled={parseData && parseData.phonenumber}
                        onChange={(e) => handleInputChange(e, "NotPopUp")}
                        required
                      />
                      <div className="response" style={{ height: "8px" }}>
                        {errors && errors.contact && (
                          <p className="text-danger m-0 fs-xs">
                            {errors.contact}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group mb-3 align-items-center">
                      <label
                        className="fw-500 text-dark me-3"
                        style={{ minWidth: "100px" }}
                      >
                        City{" "}
                      </label>
                      <input
                        type="tel"
                        className={
                          errors && errors.city
                            ? "form-control input_bg_color error-input"
                            : "form-control input_bg_color "
                        }
                        id="city"
                        placeholder="Enter your city"
                        value={formData.city}
                        onChange={(e) => handleInputChange(e, "NotPopUp")}
                        required
                        onKeyPress={(e) => {
                          if (!/^[a-zA-Z\s]*$/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                      <div className="response" style={{ height: "8px" }}>
                        {errors && errors.city && (
                          <p className="text-danger m-0 fs-xs">{errors.city}</p>
                        )}
                      </div>
                    </div>
                    <div className="form-group mb-3 align-items-center">
                      <label
                        className="fw-500 text-dark me-3"
                        style={{ minWidth: "100px" }}
                      >
                        Course
                      </label>
                      <input
                        type="tel"
                        className={
                          errors && errors.Course
                            ? "form-control input_bg_color error-input"
                            : "form-control input_bg_color cursor-not-allowed"
                        }
                        id="city"
                        placeholder="Enter your city"
                        value={courseName}
                        required
                        disabled
                      />
                      <div className="response" style={{ height: "8px" }}>
                        {errors && errors.Course && (
                          <p className="text-danger m-0 fs-xs">
                            {errors.Course}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group mb-3 align-items-center">
                      <label
                        className="fw-500 text-dark me-3"
                        style={{ minWidth: "100px" }}
                      >
                        Trainer
                      </label>
                      <Select
                        id="trainer"
                        className={"fs-s bg-form text_color input_bg_color"}
                        name="trainer"
                        options={trainerOptions}
                        value={
                          trainerOptions.find(
                            (option) => option.value === formData.trainer
                          ) || null
                        }
                        onChange={handleSelectChange}
                      />

                      <div className="response" style={{ height: "8px" }}>
                        {errors && errors.trainer && (
                          <p className="text-danger m-0 fs-xs">
                            {errors.trainer}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="fw-500 text-dark">Price Details</label>

                      {/* Check if sessionOptions has any items */}
                      {sessionOptions.length > 0 ? (
                        <select
                          className="form-select"
                          name="slot"
                          onChange={handleSlotChange}
                          required
                        >
                          <option value="" disabled selected>
                            Select Price Option
                          </option>
                          {sessionOptions.map((option) => (
                            <option
                              key={option.id}
                              value={JSON.stringify({
                                label: option.label,
                                price: option.price,
                                feeType: option.feeType
                              })}
                            >
                              {option.label}{" "}
                              {option.feeType ? option.feeType : "Free Course"} -{" "}
                              {option.price ? (
                                <>
                                  <LiaRupeeSignSolid />
                                  {option.price}
                                </>
                              ) : (
                                "Free Course"
                              )}
                            </option>
                          ))}
                        </select>
                      ) : (
                        formData.trainer && (
                          <p className="text-success">This is a Free Course</p>
                        )
                      )}

                      <div className="response" style={{ height: "8px" }}>
                        {errors && errors.slot && (
                          <p className="text-danger m-0 fs-xs">{errors.slot}</p>
                        )}
                      </div>
                    </div>

                    <div className="form-group mb-3 align-items-center">
                      <label
                        className="fw-500 text-dark me-3"
                        style={{ minWidth: "100px" }}
                      >
                        Available Batches{" "}
                      </label>

                      <Select
                        className={"fs-s bg-form text_color input_bg_color"}
                        name="availableBatchess"
                        id="availableBatchess"
                        options={slotOptions}
                        onChange={handleAvailabaleBatches}
                      />
                      <div className="response" style={{ height: "8px" }}>
                        {errors && errors.availableBatchess && (
                          <p className="text-danger m-0 fs-xs">
                            {errors.availableBatchess}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className=" mt-5">
                      <button
                        className="rbt-btn btn-sm w-100 sb-btn d-flex justify-content-center"
                        onClick={parseData?.token?handlepay:handleSubmit}
                      >
                        {parseData?.token?"pay now":"Signup to Enroll"}
                      </button>
                    </div>
                  </form>

                  {/* Social Share and Contact Section */}
                  <div className="social-share-wrapper mt-3 text-center">
                    <div className="rbt-post-share d-flex align-items-center justify-content-center">
                      <ul className="social-icon list-inline mb-3">
                        <li className="list-inline-item ">
                          <Link to="https://www.facebook.com/">
                            <CiFacebook />
                          </Link>
                        </li>
                        <li className="list-inline-item">
                          <Link to="https://www.twitter.com">
                            <CiTwitter />
                          </Link>
                        </li>
                        <li className="list-inline-item">
                          <Link to="https://www.instagram.com/">
                            <CiInstagram />
                          </Link>
                        </li>
                        <li className="list-inline-item">
                          <Link to="https://www.linkedin.com/">
                            <CiLinkedin />
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <hr className="my-3" />
                    <div className="contact-with-us text-center">
                      <p>For details about the course</p>
                      <p className="rbtbadge-2 mt-2">
                        <CiPhone /> Call Us:{" "}
                        <Link to="/course-details/1#">
                          <strong>1800-120-4748</strong>
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="modal fade"
              id="exampleModalToggle2"
              aria-hidden="true"
              aria-labelledby="exampleModalToggleLabel2"
              tabindex="-1"
            >
              <div class="modal-dialog  modal-lg">
                <div class="modal-content">
                  <div class="modal-header">
                    <h1 class="modal-title fs-5" id="exampleModalToggleLabel2">
                      Fill out the form below and we'll respond promptly
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={(e) => {
                        setStaticInput({});
                        setSlotOptionModal([]);
                        setSessionOptionsModal([]);
                        setSelectSlotModal("");
                        setErrorsPopUp();
                      }}
                    ></button>
                  </div>
                  <div class="modal-body">
                    <form className="course-enrollment-form formlistdesign">
                      <div className="row">
                        <div className="col-lg-4 form-group mb-3 align-items-center">
                          <label className="fw-500 text-dark me-3">Name </label>
                          <input
                            type="text"
                            className={
                              errorsPopUp && errorsPopUp.name
                                ? "form-control input_bg_color error-input"
                                : "form-control input_bg_color "
                            }
                            id="name"
                            placeholder="Enter your name"
                            value={staticInput.name || ""}
                            onChange={(e) => handlePopUpChange(e, "popup")}
                            required
                          />

                          <div className="response" style={{ height: "8px" }}>
                            {errorsPopUp && errorsPopUp.name && (
                              <p className="text-danger m-0 fs-xs">
                                {errorsPopUp.name}
                              </p>
                            )}
                          </div>
                        </div>
                        {/* email */}
                        <div className="col-lg-4 form-group mb-3 align-items-center">
                          <label
                            className="fw-500 text-dark me-3"
                            style={{ minWidth: "100px" }}
                          >
                            Email
                          </label>
                          <input
                            type="email"
                            className={
                              errorsPopUp && errorsPopUp.email
                                ? "form-control input_bg_color error-input"
                                : "form-control input_bg_color "
                            }
                            id="email"
                            placeholder="Enter your email"
                            value={staticInput.email || ""}
                            onChange={(e) => handlePopUpChange(e)}
                            required
                          />
                          <div className="response" style={{ height: "8px" }}>
                            {errorsPopUp && errorsPopUp.email && (
                              <p className="text-danger m-0 fs-xs">
                                {errorsPopUp.email}
                              </p>
                            )}
                          </div>
                        </div>
                        {/* contact */}
                        <div className="col-lg-4 form-group mb-3 align-items-center">
                          <label
                            className="fw-500 text-dark me-3"
                            style={{ minWidth: "100px" }}
                          >
                            Contact{" "}
                          </label>
                          <input
                            type="tel"
                            className={
                              errorsPopUp && errorsPopUp.contact
                                ? "form-control input_bg_color error-input"
                                : "form-control input_bg_color "
                            }
                            id="contact"
                            placeholder="Enter your contact number"
                            value={staticInput.contact || ""}
                            onChange={(e) => handlePopUpChange(e)}
                            required
                          />
                          <div className="response" style={{ height: "8px" }}>
                            {errorsPopUp && errorsPopUp.contact && (
                              <p className="text-danger m-0 fs-xs">
                                {errorsPopUp.contact}
                              </p>
                            )}
                          </div>
                        </div>
                        {/* city */}
                        <div className="col-lg-4 form-group mb-3 align-items-center">
                          <label
                            className="fw-500 text-dark me-3"
                            style={{ minWidth: "100px" }}
                          >
                            City{" "}
                          </label>
                          <input
                            type="tel"
                            className={
                              errorsPopUp && errorsPopUp.city
                                ? "form-control input_bg_color error-input"
                                : "form-control input_bg_color "
                            }
                            id="city"
                            placeholder="Enter your city"
                            value={staticInput.city || ""}
                            onChange={(e) => handlePopUpChange(e)}
                            required
                          />
                          <div className="response" style={{ height: "8px" }}>
                            {errorsPopUp && errorsPopUp.city && (
                              <p className="text-danger m-0 fs-xs">
                                {errorsPopUp.city}
                              </p>
                            )}
                          </div>
                        </div>
                        {/* course */}
                        <div className="col-lg-4 form-group mb-3 align-items-center">
                          <label
                            className="fw-500 text-dark me-3"
                            style={{ minWidth: "100px" }}
                          >
                            Course
                          </label>
                          <input
                            type="tel"
                            className={
                              errors && errors.Course
                                ? "form-control input_bg_color error-input"
                                : "form-control input_bg_color cursor-not-allowed"
                            }
                            id="city"
                            placeholder="Enter your city"
                            value={courseName}
                            required
                            disabled // This disables the input
                          />
                          <div className="response" style={{ height: "8px" }}>
                            {errors && errors.Course && (
                              <p className="text-danger m-0 fs-xs">
                                {errors.Course}
                              </p>
                            )}
                          </div>
                        </div>
                        {/* trainer */}
                        <div className="col-lg-4 col-md-6 col-sm-12 form-group mb-3 align-items-center">
                          <label
                            className="fw-500 text-dark me-3"
                            style={{ minWidth: "100px" }}
                          >
                            Trainer
                          </label>
                          <Select
                            id="trainer"
                            className={"fs-s bg-form text_color input_bg_color"}
                            name="trainer" // Ensure this is correct for your use case
                            options={trainerOptions}
                            value={selectedTrainer.trainerName}
                            isDisabled={true}
                            onChange={handleSelectChangePopup} // Use the correct handler
                            placeholder={selectedTrainer.trainerName}
                          />

                          <div className="response" style={{ height: "8px" }}>
                            {errors && errors.trainer && (
                              <p className="text-danger m-0 fs-xs">
                                {errors.trainer}
                              </p>
                            )}
                          </div>
                        </div>
                        {/* price details */}
                        <div className="col-lg-4 form-group mb-3">
                          <label className="fw-500 text-dark">Price Details</label>

                          {sessionOptionsModal.length > 0 ? (
                            <select
                              className="form-select"
                              name="slot"
                              onChange={handleSlotChangePopUp}
                              required
                            >
                              <option value="" disabled selected>
                                Select Price
                              </option>
                              {sessionOptionsModal.map((option) => (
                                <option
                                  key={option.id}
                                  value={JSON.stringify({
                                    label: option.label,
                                    price: option.price,
                                    feeType: option.feeType,
                                  })}
                                >
                                  {option.label} {option.feeType} - {option.price} rs
                                </option>
                              ))}
                            </select>
                          ) : (
                            ""
                          )}

                          <div className="response" style={{ height: "8px" }}>
                            {errors && errors.slot && (
                              <p className="text-danger m-0 fs-xs">{errors.slot}</p>
                            )}
                          </div>
                        </div>
                        {/* Available Batches */}
                        <div className="col-lg-4 col-md-6 col-sm-12 form-group mb-3 align-items-center">
                          <label
                            className="fw-500 text-dark me-3"
                            style={{ minWidth: "100px" }}
                          >
                            Available Batches{" "}
                          </label>
                          <Select
                            className={"fs-s bg-form text_color input_bg_color"}
                            name="availableBatchess"
                            id="availableBatchess"
                            options={slotOptionModal}
                            onChange={handleAvailableBatchesPopup}
                          />
                          <div className="response" style={{ height: "8px" }}>
                            {errors && errors.availableBatchess && (
                              <p className="text-danger m-0 fs-xs">
                                {errors.availableBatchess}
                              </p>
                            )}
                          </div>
                        </div>
                        {/* submit */}
                        <div className="d-flex justify-content-center mt-3">
                          <div className="col-lg-4 col-md-6 col-sm-12">
                            <button
                              type="button"
                              className="rbt-btn btn-sm w-100 sb-btn d-flex justify-content-center "
                              onClick={handleSubmitPopup}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="mt-5">
          <p className="bg_top_course fw-600 fs-14 text-light bg_orange bg-opacity-25">
            More Similar Courses
          </p>
          <div className="mt-1 d-flex justify-content-between">
            <h4 className="teks_blue fs-30 fw-600">
              <span className="teks_orange">Related Courses</span>
            </h4>
          </div>
          <div className="mt-1">
            <div className="row g-4">
              {relatedCourse?.map((card) => (
                <div
                  className="col-lg-3 col-md-6 col-sm-6 col-12"
                  key={card.id}
                >
                  <Link
                    to={`/courselist/course/${card.categoryName}/${card.courseName}`}
                    onClick={handleScrollToTop}
                  >
                    <div className="carousel-item active rbt-card d-flex flex-column justify-content-between ">
                      <div>
                        <img
                          src={card.imageURL}
                          className="d-block w-100 courselist-img"
                          alt="cardImage"
                        />
                        <div className="mt-2 px-3">
                          <p className="">
                            {Array.from({ length: 5 }, (_, index) => {
                              const fullStar =
                                index + 1 <= Math.floor(card?.courseRating);
                              const halfStar =
                                index + 1 > Math.floor(card?.courseRating) &&
                                index < card?.courseRating;

                              if (fullStar) {
                                return (
                                  <FaStar
                                    key={index}
                                    className="mb-1  review_color"
                                  />
                                );
                              } else if (halfStar) {
                                return (
                                  <FaStarHalfAlt
                                    key={index}
                                    className="mb-1  review_color"
                                  />
                                );
                              } else {
                                return (
                                  <FaRegStar
                                    key={index}
                                    className="mb-1  review_color"
                                  />
                                );
                              }
                            })}
                            <span className="text-dark">
                              ({card.courseRating} Reviews)
                            </span>
                          </p>
                          <h5 className="fw-700 text-dark">
                            {card.courseName}
                          </h5>

                          {/* <p className="banner_pragraph_color">
                            {card.courseTitle}
                          </p> */}
                          <p className="banner_pragraph_color">
                            {card.courseHeading}
                          </p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between px-3 py-2">
                        <p className="m-0 view-span teks_blue fw-500">
                          Know More{" "}
                          <span className="fs-5 fw-500 under-line1">→</span>
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleCourseLandingPage;
