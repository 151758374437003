import React, { useEffect, useState } from "react";
import { PiGreaterThan } from "react-icons/pi";
import { Link, useParams } from "react-router-dom";
import Yoga from "../../../../assets/images/yoga.png";
import { REAPI } from "../../../../serviceLayer/Interceptor";

const CoursesLandingPage = () => {
  const { categoryName } = useParams();
  const [courses, setCourses] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0, { behavior: "instant" });
  }, []);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const filter = JSON.stringify({ categoryName });
        const response = await REAPI.get(
          `/course/listCourses?filter=${filter}`
        );
        const coursesData = response.data.courses;
        if (!Array.isArray(coursesData)) {
          console.error("Courses data is not an array:", coursesData);
          return;
        }
        setCourses(coursesData);
        setFilteredCourses(coursesData);
      } catch (error) {
        setError("Error fetching courses. Please try again later.");
        console.error("Error fetching courses:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCourses();
  }, [categoryName]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    const filtered = courses.filter(
      (course) =>
        course.courseName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        course.courseTitle.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredCourses(filtered);
  }, [searchTerm, courses]);

  return (
    <>
      <div className="container-fluid">
        <div className="d-flex  courselist-heading">
          <div className=" col-lg-6 banner">
            <div className=" flex-wrap text-black">
              <h2 className="titles ">
                {categoryName.charAt(0).toUpperCase() + categoryName.slice(1)}
              </h2>
            </div>
          </div>
          <div className=" col-lg-6 me-3 text-end">
            <input
              type="text"
              className="search-inp"
              placeholder="Search for..."
              name="search"
              value={searchTerm}
              onChange={handleSearchChange}
              required
            />
          </div>
        </div>
      </div>

      <div className="p-0">
        <div
          className="tab-content mt-2 mb-5 container-fluid px-lg-5"
          id="pills-tabContent"
        >
          <div
            className="tab-pane fade show active"
            id="pills-all-courses"
            role="tabpanel"
            aria-labelledby="pills-all-courses-tab"
          >
            <div className="mt-2">
              <div className="row">
                {loading ? (
                  <p>Loading...</p>
                ) : error ? (
                  <p>{error}</p>
                ) : filteredCourses.length > 0 ? (
                  filteredCourses.map((course, index) => (
                    <div
                      className="mb-5 col-lg-3 col-md-4 col-sm-12"
                      key={index}
                    >
                      <Link
                        to={`/courselist/course/${course?.category}/${course?.courseName}/`}
                      >
                        <div className="rbt-cards d-flex flex-column justify-content-between rbt-hover p-0 pb-3">
                          <div className="row no-gutters">
                            <div className="col-md-12 col-lg-12">
                              <img
                                src={course.imageURL ? course.imageURL : Yoga}
                                className="card-img img-fluid courselist-img"
                                alt="Card Image"
                              />
                            </div>
                            <div className="col-md-12 col-lg-12 ps-3 mt-3 justify-content-between">
                              <div className="card-bodys">
                                <h5 className="card-titles text-dark">
                                  {course?.courseName.charAt(0).toUpperCase() +
                                    course?.courseName.slice(1)}
                                </h5>
                               
                                <p className="card-texts text-muted m-0">
                                  {course.courseHeading}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="px-2 rbt-btn-link m-0">
                            <span className="ms-2 cta">
                              <span className="view-span teks_blue fw-500">
                                Know More{" "}
                                <span className="fs-5 fw-500 under-line1">
                                  →
                                </span>
                              </span>
                            </span>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))
                ) : (
                  <p>No courses found for this category.</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CoursesLandingPage;
