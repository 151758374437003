import React, { createContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { REAPI } from '../../../../serviceLayer/Interceptor';

export const Auth = createContext();

export const AuthContext = ({ children }) => {
  const [user, setUser] = useState(null);  // Track user state (optional, but useful)
  const navigate = useNavigate();

  const UserLogin = async (formData) => {
    try {
      const response = await REAPI.post(`/user/login`, formData);
      console.log(response, "response");

      // Check if the response status is 200 (successful login)
      if (response.status === 200) {
        // Store user data and token in localStorage
        localStorage.setItem(
          "data",
          JSON.stringify({
            user: response.data?.email,
            token: response.data?.token,
            FullName: response.data?.FullName,
            phonenumber: response.data?.phonenumber,
          })
        );

        // Update state (optional)
        setUser({
          email: response.data?.email,
          token: response.data?.token,
        });

        // Navigate to the home page after successful login
        navigate("/");

      } else {
        // Handle non-200 response if needed
        console.log("Login failed: Unexpected response status", response.status);
        // Optionally show an error message to the user
      }
    } catch (error) {
      console.log("Login failed:", error);
      // Optionally show an error message to the user (e.g., "Invalid credentials")
    }
  };

  // Logout function
  const logout = () => {
    // Remove data from localStorage
    localStorage.removeItem("data");

    // Optionally update state to reflect the logged-out user
    setUser(null);

    // Redirect to login page or homepage
    navigate("/");
  };
  // const UserSignUp =(formData)=>{
  //   try {
  //     const resopnse = REAPI.post(`/user/createuser`,formData)
  //     if(resopnse.status==200){
  //       navigate("/authentication/Login")
  //     }
  //   } catch (error) {
  //     console.log("signupError" , error)
  //   }
  // }

  return (
    <Auth.Provider value={{ UserLogin, logout, /*UserSignUp*/ }}>
      {children}
    </Auth.Provider>
  );
};
