import React from "react";
import '../../../assets/css/Page10.css';

 const Page10 = () => {
    return (
        <>
            {/* <div className="newsletter_background_img">
                <p className="text-white text-center">
                    <span className=" p-2 fs-6 rounded-pill  bg_orange">
                        Get Latest Teksskillhub Updates
                    </span>
                </p>
                <h2 className="title text-white text-center">Join Teks Skill Hub Today</h2>
                <p className="text-white text-center fw-300 fs-s">
                Embark on a transformative journey to enhance your skills, art forms, and interests, guided by top instructors to elevate your career.
                </p>
                <div className="box">
                    <form action="">
                        <div className="form-box">
                            <input
                                type="email"
                                placeholder="Email Address"
                                className="small-placeholder"
                            />
                            <button className="newsletter-animated-button rbt-btn btn-sm">
                                <svg
                                    viewBox="0 0 24 24"
                                    className="arr-2"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"></path>
                                </svg>
                                <span className="ps-3 pe-1">Subscription</span>
                                <span className="newsletter-circle"></span>
                                <svg
                                    viewBox="0 0 24 24"
                                    className="arr-1"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"></path>
                                </svg>
                            </button>
                        </div>
                    </form>
                </div>
                <div className="text-center flex-container">
                    <div className="flex-item animated-text">
                        <h2 className="text-white">500 +</h2>
                        <h5 className="text-white">Successfully Trained</h5>
                        <p className="text-lightcolor">Learners & counting</p>
                    </div>

                    <div className="newsletter-divider-line"></div>

                    <div className="flex-item animated-text">
                        <h2 className="text-white">100 +</h2>
                        <h5 className="text-white">Certification Students</h5>
                        <p className="text-lightcolor">Online Course</p>
                    </div>
                </div>
            </div> */}
        </>
    )
}
export default Page10;