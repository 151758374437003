import React from 'react'
import CourseLists1 from './courselists/CourseLists1'

const CourseListsPage = () => {
  return (
    <div>
      <CourseLists1/>
    </div>
  )
}

export default CourseListsPage
