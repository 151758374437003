
import error from '../../../assets/images/404_page_cover.jpg';
import '../../../assets/css/error.css';

const Error404 = () => {
  return (
    <div>
      <div className='container-fluid error_page'>
        <div className="row d-flex justify-content-center align-items-center vh-100">
          <div className='col-lg-6'>
            <img src={error} className='img-fluid' alt='404' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Error404;
