import React, {useEffect} from 'react'

const Refund = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page
      }, []);
    return (
        <div>
            <div className="container-fluid bg-light py-4">
                <h1 className='text-center fw-700'>Refund Policy</h1>
            </div>
            <div className="container-fluid  px-5 py-5 mt-5">
                <div className="row gap-2 px-md-5 px-sm-2 py-5">
                    <div className='col-12'>
                        <h4 className='fw-600 mb-3 fs-4'>1. Information Collection :</h4>
                        <p className='fs-14'>We collect personal information such as name, email address, phone number, and other contact details from students at the time of enrollment.</p>
                    </div>

                    <div className='col-12'>
                        <h4 className='fw-600 mb-3 fs-4'>2. Use of Information :</h4>
                        <p className='fs-14'>We use the information collected to contact students regarding course updates, provide course materials, and issue certificates of completion.</p>
                    </div>

                    <div className='col-12'>
                        <h4 className='fw-600 mb-3 fs-4'>3. Information Sharing :</h4>
                        <p className='fs-14'>We do not share personal information with any third parties without the student’s consent, except as required by law.</p>
                    </div>

                    <div className='col-12'>
                        <h4 className='fw-600 mb-3 fs-4'>4. Cookies :</h4>
                        <p className='fs-14'>We use cookies on our website to track user behavior and improve the user experience. Students can disable cookies in their web browser if they choose to do so.</p>
                    </div>

                    <div className='col-12'>
                        <h4 className='fw-600 mb-3 fs-4'>5. Data Retention :</h4>
                        <p className='fs-14'>We retain personal information for as long as necessary to provide the course and related services, or until the student requests that their information be deleted.</p>
                    </div>

                    <div className='col-12'>
                        <h4 className='fw-600 mb-3 fs-4'>6. Modification :</h4>
                        <p className='fs-14'>We reserve the right to modify this privacy policy at any time without prior notice.</p>
                    </div>

                </div>
                <blockquote className='bg-info-subtle py-4 px-3' _ngcontent-aae-c104=""><strong _ngcontent-aae-c104="">NOTE: </strong>By enrolling in our coaching center, you acknowledge that you have read, understood, and agree to abide by our terms and conditions and privacy policy.</blockquote>
            </div>
        </div>
    )
}

export default Refund