import axios from "axios";

const REAPI = axios.create({
    baseURL: process.env.REACT_APP_TEKSSKILL_API_URL,
    // baseURL: 'https://betaapi.getpositivereviews.in',
})

// Add a request interceptor
REAPI.interceptors.request.use(
    (config) => {
        const data = JSON.parse(localStorage.getItem('data'));
        if (data?.token) {
            // config.headers.Authorization = `${data.token}`
            config.headers["Authorization"] = `Bearer ${data.token}`


        }
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
)


//  check responce interceptor

// ERPApi.interceptors.response.use(
//     (response) => {
//         return response;
//     },
//     (error) => {
//         const navigate = useNavigate()
//         if (error.response && error.response.status === 400) {
//             console.log(error, "errorhere")
//             return Promise.reject(error);
//         }
//         return Promise.reject(error);
//     }
// );

const setupInterceptors = (navigate) => {
    REAPI.interceptors.response.use(
        (response) => {
            return response
        },
        (error) => {
            // if (error) {
            //     const message = error.message;
            //     if (message === "Network Error") {
            //         navigate('/500');
            //     }
            // }
            // if (error.response) {
            //     const status = error.response.status;
            //     if (status >= 500 && status < 600) {
            //         navigate('/500');
            //     }
            //     // if (status === 400 ) {
            //     //     navigate('/500');
            //     // }
            // }
            return Promise.reject(error);
        }
    );
};


export { REAPI, setupInterceptors };
