import React from 'react'
import About1 from './about/About1'
import About2 from './about/About2'
import About3 from './about/About3'
import About4 from './about/About4'
import About5 from './about/About5'

const AboutPages = () => {
  return (
  <div>
      <About1 />
   
  </div>
  )
}

export default AboutPages