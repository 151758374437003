import React from 'react'
import { Route, Routes } from 'react-router-dom'
import BecameTeacher from '../../componentLayer/pages/bacameteacher/BecameTeacher'
import ThankYou from '../../utils/thankyoupage/ThankYou'

const BecameTeacherRouter = () => {
  return (
   <Routes >
    <Route path='/*' element={<BecameTeacher />} /> 
    <Route path="/thankyou" element={<ThankYou />} />
   </Routes>
  )
}

export default BecameTeacherRouter