import React from 'react'
import { Route, Routes } from "react-router-dom";
import "../../assets/css/Course1.css"
import CoursesLandingPage from '../../componentLayer/pages/teachingCourses/courses/CoursesLandingPage';
import SingleCourseLandingPage from '../../componentLayer/pages/teachingCourses/courses/SingleCourseLandingPage';
import ThankYou from '../../utils/thankyoupage/ThankYou';
const CoursesRoutes = () => {
  return (
    <div>
      <Routes>      
        <Route path="/:categoryName" element={<CoursesLandingPage />} />
        <Route path="/:categoryName/:courseName" element={<SingleCourseLandingPage />} />  
        <Route path='/:categoryName/:courseName/thankyou' element={<ThankYou />} />
      </Routes>
    </div>
  )
}

export default CoursesRoutes
