// src/index.js
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { AuthContext } from "./componentLayer/components/Context/authContext/AuthContext";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthContext>  {/* Wrap App with AuthContext */}
        <App />
      </AuthContext>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
