import React, { useState, useEffect } from 'react';
import { MdOutlineLocalPhone, MdOutlineEmail } from "react-icons/md";
import { IoLocationOutline } from "react-icons/io5";
import axios from 'axios';
import "../../../assets/css/ContactUs.css";
import Select from 'react-select';
import { Link, useNavigate } from 'react-router-dom';
import { REAPI } from '../../../serviceLayer/Interceptor';
import contactus from "../../../assets/images/contact.png"
const ContactUs = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phonenumber: '',
    courseName: '',
    message: '',
  });

  const [errors, setErrors] = useState({});
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0); 
    const fetchCourses = async () => {
      try {
        const response = await REAPI.get(`/course/courses`);
        const options = response.data.map(course => ({
          value: course.courseName,
          label: course.courseName,
        }));
        setCourses(options);
      } catch (error) {
        console.error('Error fetching courses', error);
      }
    };

    fetchCourses();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'name') {
      const filteredValue = value.replace(/[^a-zA-Z\s]/g, '');
      setFormData((prevData) => ({
        ...prevData,
        [name]: filteredValue,
      }));
    } else if (name === 'phonenumber') {
      const filteredValue = value.replace(/[^0-9]/g, '');
      setFormData((prevData) => ({
        ...prevData,
        [name]: filteredValue,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleCourseChange = (selectedOption) => {
    setSelectedCourse(selectedOption);
    setFormData((prevData) => ({
      ...prevData,
      courseName: selectedOption ? selectedOption.value : '',
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      courseName: '',
    }));
  };

  const validateForm = () => {
    let formErrors = {};

    if (!formData.name) {
      formErrors.name = 'Name is required';
    } else if (formData.name.trim().length < 3) {
      formErrors.name = "Name must contain at least 3 characters";
    } else if (!/^[A-Za-z\s]+$/.test(formData.name)) {
      formErrors.name = "Name must contain only letters";
    }

    if (!formData.email) {
      formErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = 'Email address is invalid';
    }

    if (!formData.phonenumber) {
      formErrors.phonenumber = 'Contact number is required';
    } else if (formData.phonenumber.length !== 10) {
      formErrors.phonenumber = 'Contact number must be exactly 10 digits';
    }

    if (!formData.courseName) {
      formErrors.courseName = 'Please select a course';
    }

    if (!formData.message) {
      formErrors.message = 'Please enter a message';
    } else if (formData.message.trim().length < 3) {
      formErrors.message = 'Message must contain at least 3 characters';
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateForm();
    if (!isValid) return;

    try {
      await REAPI.post(`/contactUs/contactUs`, formData);
      setFormData({
        name: '',
        email: '',
        phonenumber: '',
        courseName: '',
        message: '',
      });
      setSelectedCourse(null);
      setErrors({});
      navigate("/contactus/thankyou");
    } catch (error) {
      console.error('Error submitting the form', error);
    }
  };

  return (
    <div className="container-fluid px-0">
      {/* Banner Start */}
      <div className='bg_contact_gradient mb-4'>
        <div className="container">
          <div className="row">
            <div className="col d-flex justify-content-center px-0 align-items-center gap-3 pt-4 p-5 my-4 flex-column">
              <span className='bg_orange text-light text-center rounded-pill wid-3r mb-4 py-2'>Contact Us</span>
              <h1 className="title text-center fs-2 fw-700 mb-0 text-black">We’re excited to hear from you! </h1>
              <h1 className="title text-center fs-2 fw-700 mb-0 text-black">Got questions or need help ?</h1>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="p-3 contact-card mb-4 gap-3 px-4 lh-100 w-25 rounded shadow-sm d-flex flex-column w-100 bg-white">
                <MdOutlineLocalPhone className='fs-44 fw-700' />
                <h5 className='fs-16 fw-600 my-0'>Contact Number</h5>
                <p className='my-0 fs-13 text-dark mb-3'>9133308354</p>
               
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="p-3 contact-card mb-4 gap-3 px-4 lh-100 w-25 rounded shadow-sm d-flex flex-column w-100 bg-white">
                <MdOutlineEmail className='fs-44 fw-700' />
                <h5 className='fs-16 fw-600 my-0'>Our Email Address</h5>
                <Link to="mailto:support@teksskillhub.com" className='my-0 fs-13 text-dark mb-3'>support@teksskillhub.com</Link>
              
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="p-3 contact-card mb-4 gap-3 px-4 lh-100 w-25 rounded shadow-sm d-flex flex-column w-100 bg-white mb-4">
                <IoLocationOutline className='fs-44 fw-700' />
                <h5 className='fs-16 fw-600 my-0'>Our Location</h5>
                <p className='my-0 fs-13 text-dark'> H.no: 9- 1-127/4, Flat No: 501 & 502 5th Floor, Amsri Faust Building, S.D. Road, Secunderabad, Telangana 500025</p>
             
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Form & Picture start */}
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-md-12">
            <div className="overflow-hidden object-fit-contain rounded">
              <img className='w-100 mt-4 mb-4  shadow rounded mb-4 px-0 contact-img h-75' src={contactus} alt="Contact Us" />
            </div>
          </div>
          <div className="col-lg-7 col-md-12">
            <div className="shadow py-2 px-3 rounded mt-4">
              <div className="form-headings px-lg-5 mt-2">
               
                <p className='fs-2 fw-700 mt-4 px-0'>Contact Us</p>
              </div>
              <form className='col-12 d-flex flex-column gap-2 px-lg-5'>
                <div className="form-group mb-0">
                  <label className="fw-500 text-dark">Name</label>
                  <input
                    name="name"
                    className={`form-control custom-border-bottom w-100 ${errors.name ? 'is-invalid' : ''}`}
                    type="text"
                    placeholder="Enter Name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                  <div style={{ height: "8px" }}>
                    {errors && errors?.name && (
                      <p className="text-danger m-0 fs-xs">
                        {errors?.name}
                      </p>
                    )}
                  </div>
                </div>

                <div className="form-group mb-0">
                  <label className="fw-500 text-dark">Email</label>
                  <input
                    name="email"
                    className={`form-control custom-border-bottom w-100 ${errors.email ? 'is-invalid' : ''}`}
                    type="email"
                    placeholder="Enter Email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  <div style={{ height: "8px" }}>
                    {errors && errors?.email && (
                      <p className="text-danger m-0 fs-xs">
                        {errors?.email}
                      </p>
                    )}
                  </div>
                </div>

                <div className="form-group mb-0">
                  <label className="fw-500 text-dark">Phone Number</label>
                  <input
                    name="phonenumber"
                    className={`form-control custom-border-bottom w-100 ${errors.phonenumber ? 'is-invalid' : ''}`}
                    type="text"
                    placeholder="Enter Mobile Number"
                    maxLength="10"
                    value={formData.phonenumber}
                    onChange={handleChange}
                  />
                  <div style={{ height: "8px" }}>
                    {errors && errors?.phonenumber && (
                      <p className="text-danger m-0 fs-xs">
                        {errors?.phonenumber}
                      </p>
                    )}
                  </div>
                </div>

                <div className="form-group mb-0">
                  <label className="fw-500 text-dark">Course Name</label>
                  <Select
                    name="courseName"
                    value={selectedCourse}
                    onChange={handleCourseChange}
                    options={courses}
                    className={`custom-border-bottom w-100 ${errors.courseName ? 'select_is_invalid' : ''}`}
                    placeholder="Select Course"
                    styles={{
                      placeholder: (base) => ({
                        ...base,
                        fontSize: '0.8125rem',
                        color: '#6c757d',
                      }),
                    }}
                  />
                  <div style={{ height: "8px" }}>
                    {errors && errors?.courseName && (
                      <p className="text-danger m-0 fs-xs">
                        {errors?.courseName}
                      </p>
                    )}
                  </div>
                </div>

                <div className="form-group mb-0">
                  <label className="fw-500 text-dark">Message</label>
                  <textarea
                    name="message"
                    className={`form-control custom-border-bottom w-100 ${errors.message ? 'is-invalid' : ''}`}
                    placeholder="Enter your message"
                    rows="4"
                    value={formData.message}
                    onChange={handleChange}
                  />
                  <div style={{ height: "8px" }}>
                    {errors && errors?.message && (
                      <p className="text-danger m-0 fs-xs">
                        {errors?.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className='text-end'>
                  <button type="submit" className='rbt-btn btn-sm ' onClick={handleSubmit}>Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
