import React from 'react'
import { Route, Routes } from 'react-router-dom'
import AboutPages from '../../componentLayer/pages/AboutPages'
import About1 from '../../componentLayer/pages/about/About1'
import About2 from '../../componentLayer/pages/about/About2'
import About5 from '../../componentLayer/pages/about/About5'
import About4 from '../../componentLayer/pages/about/About4'
import About3 from '../../componentLayer/pages/about/About3'

const AboutRoutes = () => {
  return (
    <Routes>
        <Route path='/*' element={<AboutPages />} />
     
        
    </Routes>
  )
}

export default AboutRoutes