import React, { useEffect } from "react";
import thankyou from '../../assets/images/thankyou.png'

const ThankYou = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page
      }, []);
    return (
        <div className='d-flex justify-content-center my-5 col-12'>
            <img src={thankyou} alt="" className="w-100" />
        </div>
    )
}

export default ThankYou