import React from 'react'
import { ClipLoader } from 'react-spinners'
const LoadingScreen = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    <ClipLoader />
    </div>
  )
}

export default LoadingScreen