import React from 'react'
import { Route, Routes } from "react-router-dom";
import CourseListsPage from '../../componentLayer/pages/CourseListsPage';
import CourseLists1 from '../../componentLayer/pages/courselists/CourseLists1';
import CoursesRoutes from '../courses/CoursesRoutes';
const CourseListsRoutes = () => {
  return (
    <div>
      <Routes>
      <Route path="/*" element={<CourseListsPage/>} />
      <Route path="/course/*" element={<CoursesRoutes />} />
      </Routes>
    </div>
  )
}

export default CourseListsRoutes
