import React,{useEffect} from 'react'
import '../../../assets/css/terms.css'

const Terms = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page
      }, []);
  return (
    <div>
        <div className="container-fluid bg-light py-4">
            <h1 className='text-center fw-700'>Terms & Conditions</h1>
        </div>
        <div className="container-fluid  px-md-5 px-sm-2 ">
        <div className="row gap-2 ">
        <div className='col-12 ps-5'>
    <h4 className='fw-600 mb-4 mt-5 fs-4'>Admission :</h4>
    <ul className= 'px-md-5 px-sm-0'>
        <li className='fs-14 lists mb-3'>The eligibility criteria for each course will be clearly communicated to the students before admission, and students must provide all required documents and information during the admission process.</li>
        <li className='fs-14 lists mb-3'>Admission will be confirmed only after the completion of all formalities and payment of full fees.</li>
    </ul>
    
    <h4 className='fw-600 mb-4 mt-5 fs-4'>Fee :</h4>
    <ul className= 'px-md-5 px-sm-0'>
        <li className='fs-14 lists mb-3'>Fees for each course will be clearly communicated to the students before admission, and they are non-refundable except in case of course cancellation by the center or extenuating circumstances that prevent the student from continuing the course. In both cases, payment will be refunded after deductions.</li>
        <li className='fs-14 lists mb-3'>No course fee adjustment will be allowed from one course to another or from one student to another.</li>
        <li className='fs-14 lists mb-3'>Any request for course fee adjustment will not be entertained by the coaching center under any circumstances.</li>
        <li className='fs-14 lists mb-3'>All course fees are non-transferable and non-refundable. Students are advised to choose their courses carefully and make sure they are fully committed to the course before paying the course fee.</li>
        <li className='fs-14 lists mb-3'>In case a student is unable to complete the course due to valid reasons, the course fee will not be refunded or adjusted against any other course.</li>
        <li className='fs-14 lists mb-3'>Late payment of fees may attract additional charges as per the center’s policy.</li>
    </ul>

    <h4 className='fw-600 mb-4 mt-5 fs-4'>Course Material :</h4>
    <ul className= 'px-md-5 px-sm-0'>
        <li className='fs-14 lists mb-3'>The course material and digital goods provided by the coaching center are copyrighted and cannot be reproduced or used for commercial purposes without permission.</li>
        <li className='fs-14 lists mb-3'>Any damage or loss of course material will be the responsibility of the student and may attract additional charges for extra material copies as per the center’s policy.</li>
    </ul>

    <h4 className='fw-600 mb-4 mt-5 fs-4'>Attendance :</h4>
    <ul className= 'px-md-5 px-sm-0'>
        <li className='fs-14 lists mb-3'>Regular attendance is essential for successfully completing the course and obtaining a certificate.</li>
        <li className='fs-14 lists mb-3'>Students must inform the center in advance if they are unable to attend a class due to valid reasons such as illness or emergencies.</li>
        <li className='fs-14 lists mb-3'>Make-up classes may be arranged at the discretion of the center and subject to availability of resources.</li>
    </ul>

    <h4 className='fw-600 mb-4 mt-5 fs-4'>Conduct :</h4>
    <ul className= 'px-md-5 px-sm-0'>
        <li className='fs-14 lists mb-3'>Students must conduct themselves respectfully towards the center staff, fellow students, and the center’s property.</li>
        <li className='fs-14 lists mb-3'>Any form of harassment, discrimination, or bullying will not be tolerated and may lead to immediate expulsion from the center.</li>
        <li className='fs-14 lists mb-3'>The use of drugs or alcohol within the center’s premises is strictly prohibited and may lead to immediate expulsion from the center.</li>
    </ul>

    <h4 className='fw-600 mb-4 mt-5 fs-4'>Examinations :</h4>
    <ul className= 'px-md-5 px-sm-0'>
        <li className='fs-14 lists mb-3'>The examination schedule and format will be communicated to the students in advance.</li>
        <li className='fs-14 lists mb-3'>Students must adhere to the rules and regulations of the examination, and any deviation may lead to disqualification or cancellation of the examination.</li>
        <li className='fs-14 lists mb-3'>Students must return all examination papers and materials to the center after the examination is over.</li>
    </ul>

    <h4 className='fw-600 mb-4 mt-5 fs-4'>Certification :</h4>
    <ul className= 'px-md-5 px-sm-0'>
        <li className='fs-14 lists mb-3'>The certificate will only be awarded to students who successfully complete the course as per the center’s criteria.</li>
        <li className='fs-14 lists mb-3'>The certificate does not guarantee employment or acceptance into any institution.</li>
    </ul>

    <h4 className='fw-600 mb-4 mt-5 fs-4'>Liability :</h4>
    <ul className= 'px-md-5 px-sm-0'>
        <li className='fs-14 lists mb-3'>The center is not responsible for any injury, loss, or damage to the students or their belongings within the center’s premises or during any offsite activity.</li>
        <li className='fs-14 lists mb-3'>Students must take responsibility for their personal safety and belongings while attending classes at the center or any other location.</li>
    </ul>

    <h4 className='fw-600 mb-4 mt-5 fs-4'>Change in Policies :</h4>
    <ul className= 'px-md-5 px-sm-0'>
        <li className='fs-14 lists mb-3'>The center may revise its policies, rules and regulations, course structure, fees, timings, or any other aspect of the coaching center from time to time without prior notice to the students.</li>
        <li className='fs-14 lists mb-3'>The revised policies will be applicable to all existing and new students.</li>
    </ul>

    <h4 className='fw-600 mb-4 mt-5 fs-4'>Dispute Resolution :</h4>
    <ul className= 'px-md-5 px-sm-0'>
        <li className='fs-14 lists mb-3'>Any dispute arising out of or related to these terms and conditions shall be resolved amicably through mutual discussion and agreement between the coaching center and the student.</li>
    </ul>

    <h4 className='fw-600 mb-4 mt-5 fs-4'>Termination of Admission :</h4>
    <ul className= 'px-md-5 px-sm-0'>
        <li className='fs-14 lists mb-3'>The center reserves the right to terminate the admission of any student at any time, without assigning any reason.</li>
        <li className='fs-14 lists mb-3'>In such cases, the center may refund a portion of the fees, which depends on the center's decision and the duration of the course completed by the student and the center’s policy.</li>
    </ul>

    <h4 className='fw-600 mb-4 mt-5 fs-4'>Placement Guarantee :</h4>
    <ul className= 'px-md-5 px-sm-0'>
        <li className='fs-14 lists mb-3'>The center does not provide any placement guarantee to the students but may assist them in finding suitable job opportunities through guidance, counseling, and if any recommendations.</li>
    </ul>

    <h4 className='fw-600 mb-4 mt-5 fs-4'>Using ID Card :</h4>
    <ul className= 'px-md-5 px-sm-0'>
        <li className='fs-14 lists mb-3'>Each student will be issued an identification card (ID card) by the coaching center, and it must be carried by the student at all times while attending classes or any other activities conducted by the center.</li>
    </ul>

    <h4 className='fw-600 mb-4 mt-5 fs-4'>Copying Institute Content :</h4>
    <ul className= 'px-md-5 px-sm-0'>
        <li className='fs-14 lists mb-3'>Copying or distributing any of the coaching center’s course material, including lectures, notes, presentations, or any other content, is strictly prohibited. Any violation of this rule may lead to immediate expulsion from the center and legal action may be taken against the student.</li>
    </ul>

    <h4 className='fw-600 mb-4 mt-5 fs-4'>Absconding :</h4>
    <ul className= 'px-md-5 px-sm-0'>
        <li className='fs-14 lists mb-3'>Absconding, or leaving the coaching center without informing the center authorities, is not permitted. In case a student wishes to discontinue the course, they must inform the center in writing and complete all formalities as required.</li>
    </ul>

    <h4 className='fw-600 mb-4 mt-5 fs-4'>Attendance and Absence :</h4>
    <ul className= 'px-md-5 px-sm-0'>
        <li className='fs-14 lists mb-3'>Regular attendance is important for the successful completion of the course.</li>
        <li className='fs-14 lists mb-3'>Students must inform the center in advance if they are unable to attend a class due to valid reasons such as illness or emergencies.</li>
        <li className='fs-14 lists mb-3'>If a student is absent for 3 consecutive days without intimation, their admission may be terminated without refund.</li>
    </ul>

    <h4 className='fw-600 mb-4 mt-5 fs-4'>Teaching Staff :</h4>
<ul className= 'px-md-5 px-sm-0'>
    <li className='fs-14 lists mb-3'>While the center will endeavor to provide training with a specific teaching staff, there is no commitment to do so.</li>
    <li className='fs-14 lists mb-3'>The center reserves the right to assign trainers on the basis of availability, and students cannot demand a specific trainer.</li>
</ul>

<h4 className='fw-600 mb-4 mt-5 fs-4'>Course Fees :</h4>
<ul className= 'px-md-5 px-sm-0'>
    <li className='fs-14 lists mb-3'>The course fees may vary from student to student, based on their merit and other relevant factors as determined by the center.</li>
    <li className='fs-14 lists mb-3'>The center reserves the right to change the course fees at any time without prior notice.</li>
</ul>

<h4 className='fw-600 mb-4 mt-5 fs-4'>Course Curriculum :</h4>
<ul className= 'px-md-5 px-sm-0'>
    <li className='fs-14 lists mb-3'>The center reserves the right to update the course curriculum as per its requirements, without any prior notice to the students.</li>
    <li className='fs-14 lists mb-3'>Students are expected to keep themselves updated with any changes in the course curriculum.</li>
</ul>

<h4 className='fw-600 mb-4 mt-5 fs-4'>Course Duration :</h4>
<ul className= 'px-md-5 px-sm-0'>
    <li className='fs-14 lists mb-3'>The course duration may vary from batch to batch, depending on factors such as students’ attendance, training methodology, and other relevant factors as determined by the center.</li>
    <li className='fs-14 lists mb-3'>The center reserves the right to change the course duration at any time without prior notice.</li>
</ul>

<h4 className='fw-600 mb-4 mt-5 fs-4'>Paid Internship Support :</h4>
<ul className= 'px-md-5 px-sm-0'>
    <li className='fs-14 lists mb-3'>The coaching center may assist students in finding suitable paid internships based on their skills and interests.</li>
    <li className='fs-14 lists mb-3'>The coaching center will not guarantee any specific internship or job placement.</li>
    <li className='fs-14 lists mb-3'>The coaching center may charge a separate fee for providing internship support services.</li>
    <li className='fs-14 lists mb-3'>The coaching center will not be liable for any issues or disputes that arise between the student and the internship provider.</li>
</ul>

<h4 className='fw-600 mb-4 mt-5 fs-4'>Project Assignment :</h4>
<ul className= 'px-md-5 px-sm-0'>
    <li className='fs-14 lists mb-3'>The coaching center will provide practice projects to the students for their learning and skill development.</li>
    <li className='fs-14 lists mb-3'>The projects assigned may be either live or previously completed projects, depending on availability and suitability.</li>
    <li className='fs-14 lists mb-3'>Students must complete the project within the given time frame and submit it to the center for evaluation.</li>
</ul>

<h4 className='fw-600 mb-4 mt-5 fs-4'>Intellectual Property :</h4>
<ul className= 'px-md-5 px-sm-0'>
    <li className='fs-14 lists mb-3'>All intellectual property created by students during the live project or internship will belong to the student.</li>
    <li className='fs-14 lists mb-3'>The coaching center may use such intellectual property for promotional or educational purposes with the student’s consent.</li>
    <li className='fs-14 lists mb-3'>The coaching center will not claim any ownership rights over the student’s intellectual property.</li>
</ul>

   
        </div>
        </div>
        </div>
    </div>
  )
}

export default Terms