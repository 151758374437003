import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Auth } from '../../componentLayer/components/Context/authContext/AuthContext'; // Make sure the path is correct
import '../../assets/css/login.css'
import logo from '../../assets/images/Teks_Skill_HubLogo_05.png'

export const Login = () => {

    const { UserLogin } = useContext(Auth); // Access the UserLogin function from context

    // Login button disable state
    const [isValid, setValid] = useState({
        validMail: false,
        validPassword: false,
        loading: false,
        loginBtn: true
    });

    // Navigation to home page after login
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });

    const [error, setError] = useState({});

    // Submit handler
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if there are any validation errors
        if (Object.keys(error).length !== 0) {
            return;
        }

        // Call the UserLogin function from context
        try {
            await UserLogin(formData); // Use the context's UserLogin function
            navigate('/'); // Redirect after successful login
        } catch (err) {
            console.log('Login failed:', err);
        }
    };

    // Login button enable/disable logic based on email and password validity
    useEffect(() => {
        if (isValid.validMail && isValid.validPassword) {
            setValid({ ...isValid, loginBtn: false });
        } else {
            setValid({ ...isValid, loginBtn: true });
        }
    }, [formData.email, formData.password]);

    // Handle form input changes and validation
    const handleInputs = (e, field) => {
        const value = e.target.value;
        if (field === 'email') {
            setFormData({
                ...formData,
                email: value
            });

            // Validate email format
            if (/\S+@\S+\.\S+/.test(value)) {
                setError({});
                setValid({
                    ...isValid,
                    validMail: true
                });
            } else {
                setError({
                    ...error,
                    email: 'Invalid email format'
                });
                setValid({
                    ...isValid,
                    validMail: false
                });
            }
        } else if (field === 'password') {
            setFormData({
                ...formData,
                password: value
            });

            // Validate password length
            if (value.length >= 6) {
                setError({});
                setValid({
                    ...isValid,
                    validPassword: true
                });
            } else {
                setError({
                    ...error,
                    password: 'Password must contain at least 6 characters'
                });
                setValid({
                    ...isValid,
                    validPassword: false
                });
            }
        }
    };

    return (
        <div className="login_bg_image w-full vh-100">
            <div className='container'>
                <div className="row justify-content-center">
                    <div className="col-12 text-center mt-sm-5 mb-1 text-white-50">
                        <img src={logo} alt="" srcset="" style={{ width: "230px" }} />
                    </div>
                    <div className="col-md-8 col-lg-6 col-xl-5">
                        <div className="shadow bg-white p-4 mt-4 rounded">
                            <h2 className="text-center mb-4 teks_blue fs-16 fw-500">Welcome back!</h2>
                            <form onSubmit={handleSubmit} className='text-start'>
                                <div className="mb-3">
                                    <label htmlFor="email" className="  fs-13 fw-500 mb-2">Email</label>
                                    <input
                                        type="email"
                                        className="form-control fs-s bg-form text_color input_bg_color"
                                        id="email"
                                        placeholder="name@example.com"
                                        onChange={(e) => handleInputs(e, 'email')}
                                    />
                                    {error.email && (
                                        <p className="text-danger m-0 fs-xs">{error.email}</p>
                                    )}
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="password" className="  fs-13 fw-500 mb-2">Password</label>
                                    <input
                                        type="password"
                                        className="form-control fs-s bg-form text_color input_bg_color"
                                        id="password"
                                        placeholder="********"
                                        onChange={(e) => handleInputs(e, 'password')}
                                    />
                                    {error.password && (
                                        <p className="text-danger m-0 fs-xs">{error.password}</p>
                                    )}
                                </div>


                                {/* <div className="mb-3"> */}
                                    <div class="form-check my-3">
                                        <input id="auth-remember-check" type="checkbox" class="form-check-input" />
                                        <label for="auth-remember-check" class="form-check-label  fs-13 fw-500">Remember me</label>
                                        <div class="float-end"><span class="text-mute fs-13 fw-500">
                                            <Link to="/Login/ForgotPassword" /*style="cursor: pointer; color: rgb(14, 165, 236);"*/>
                                                Forgot password?
                                            </Link>
                                        </span>
                                        </div>
                                    </div>
                                    {/* <span className="text-primary text-end">
                                        <Link to="/authentication/Login/ForgotPassword">Forgot Password?</Link>
                                    </span>
                                </div> */}


                                <button className="btn btn-primary w-100" type="submit" disabled={isValid.loginBtn}>
                                    Login
                                </button>
                                {/* <div className="mb-3">
                                <span className="text-end">
                                    don't have account
                                    <Link to="/authentication/Signup"> Signup here</Link>
                                </span>
                            </div> */}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

